import PanelModel from "./PanelModel";

export default class PanelConfigModel {
    constructor(
        id: string,
        minWidth:number,
        maxWidth:number,
        panels: PanelModel[],
    ) {
        this.id = id;
        this.panels = panels;
        this.minWidth = minWidth;
        this.maxWidth = maxWidth;
    }
}