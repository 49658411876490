import React, {createContext, useEffect, useLayoutEffect, useMemo, useState} from "react";
import DoorSelectorView from "views/Door/DoorTypeSelectionView";
import CustomizeDoorView from "views/Door/CustomizeDoorView";
import {Navigate, useParams} from "react-router-dom";
import optionConfigJson from 'assets/option_config.json';
import DoorModel from 'models/DoorModel'
import RoomTypeModel from "../models/RoomTypeModel";
import RoomTypeFlooringModel from "../models/RoomTypeFlooringModel";
import DimensionModel from "../models/DimensionModel";
import GlassOpacityModel from "../models/GlassOpacityModel";
import DividerDesignModel from "../models/DividerDesignModel";
import FrameFinishModel from "../models/FrameFinishModel";
import PanelModel from "../models/PanelModel";
import PanelConfigModel from "../models/PanelConfigModel";
import PriceMultiplierDataModel from "../models/PriceMultiplierDataModel";
import SwingDirectionModel from "../models/SwingDirectionModel";
import DoorHandlingModel from "../models/DoorHandlingModel";
import AnimationOptionModel from "../models/AnimationOptionModel";

//==================== Class variable ====================
export const DoorContext = createContext(null);

//==================== Public functions ====================
export function DoorTypeSelector(props) {
    useEffect(() => {
        props.setPageTitle('OWN');
    });

    let DoorOptions = [];

    for (let doorOption of optionConfigJson['options']) {
        DoorOptions.push(
            new DoorModel(
                doorOption.id,
                doorOption.name,
                doorOption.image
            )
        );
    }

    return (
        <DoorSelectorView setPageTitle={props.setPageTitle} doorOptions={DoorOptions}/>
    );
}

export function CustomizeDoor(props) {
    const {door_type} = useParams();

    const selectedDoorOption = useMemo(() => {
        for (let doorOption of optionConfigJson['options']) {
            if (doorOption.id === door_type) {
                return doorOption;
            }
        }

        return null;
    }, []);

    const doorParams = useMemo(() => {
        const getDimensionData = (dimension) => {
            return new DimensionModel(
                dimension.width,
                dimension.height,
                dimension.minWidth,
                dimension.maxWidth,
                dimension.minHeight,
                dimension.maxHeight,
            )
        }

        const getRoomTypeData = (roomTypes) => {
            let roomTypeOptions = [];
            for (let roomTypeIndex = 0; roomTypeIndex < roomTypes.length; roomTypeIndex++) {
                let roomTypeOption = roomTypes[roomTypeIndex];

                let roomTypeFlooringOptions = [];
                for (let roomTypeFlooringIndex = 0; roomTypeFlooringIndex < roomTypeOption.roomTypeFlooring.length; roomTypeFlooringIndex++) {
                    let roomTypeFlooringOption = roomTypeOption.roomTypeFlooring[roomTypeFlooringIndex];
                    roomTypeFlooringOptions.push(new RoomTypeFlooringModel(
                        roomTypeFlooringOption.id,
                        roomTypeFlooringOption.name,
                        roomTypeFlooringOption.roomImage,
                        roomTypeFlooringOption.floorImage,
                        roomTypeFlooringOption.wallImage,
                        roomTypeFlooringOption.filterImage,
                        roomTypeFlooringOption.furnitureImage
                    ));
                }


                roomTypeOptions.push(
                    new RoomTypeModel(
                        roomTypeOption.id,
                        roomTypeOption.name,
                        roomTypeOption.icon,
                        roomTypeFlooringOptions
                    )
                );
            }

            return roomTypeOptions;
        }

        const getPanelConfigsData = (panelConfigs) => {
            let panelOptions = [];

            for (let panelConfigIndex = 0; panelConfigIndex < panelConfigs.length; panelConfigIndex++) {
                let panels = [];

                let currentPanelConfig = panelConfigs[panelConfigIndex];

                for (let panelConfigPanelIndex = 0; panelConfigPanelIndex < currentPanelConfig.panels.length; panelConfigPanelIndex++) {
                    let animationOptions = []

                    let currentPanel = currentPanelConfig.panels[panelConfigPanelIndex];

                    for (let animationOptionIndex = 0; animationOptionIndex < currentPanel.animationOptions.length; animationOptionIndex++) {
                        animationOptions.push(new AnimationOptionModel(
                            currentPanel.animationOptions[animationOptionIndex].id,
                            currentPanel.animationOptions[animationOptionIndex].animation,
                        ));
                    }

                    panels.push(new PanelModel(
                        currentPanel.id,
                        currentPanel.style,
                        animationOptions,
                    ));
                }

                panelOptions.push(
                    new PanelConfigModel(
                        currentPanelConfig.id,
                        currentPanelConfig.minWidth,
                        currentPanelConfig.maxWidth,
                        panels
                    )
                );
            }

            return panelOptions;
        }

        const getGlassOpacitiesData = (glassOpacities) => {
            let glassOpacityOptions = [];

            for (let glassOpacityIndex = 0; glassOpacityIndex < glassOpacities.length; glassOpacityIndex++) {
                glassOpacityOptions.push(
                    new GlassOpacityModel(
                        glassOpacities[glassOpacityIndex].id,
                        glassOpacities[glassOpacityIndex].name,
                        glassOpacities[glassOpacityIndex].image,
                        glassOpacities[glassOpacityIndex].icon,
                        glassOpacities[glassOpacityIndex].style
                    )
                );
            }

            return glassOpacityOptions;
        }

        const getDoorHandlingsData = (doorHandlings) => {
            let output = [];

            for (let index = 0; index < doorHandlings.length; index++) {
                output.push(
                    new DoorHandlingModel(
                        doorHandlings[index].id,
                        doorHandlings[index].name,
                        doorHandlings[index].icon
                    )
                );
            }

            return output;
        }

        const getSwingDirectionsData = (swingDirections) => {
            let output = [];

            for (let index = 0; index < swingDirections.length; index++) {
                output.push(
                    new SwingDirectionModel(
                        swingDirections[index].id,
                        swingDirections[index].name,
                        swingDirections[index].icon
                    )
                );
            }

            return output;
        }

        const getDividerDesignsData = (dividerDesigns) => {
            let dividerDesignOptions = [];

            for (let index = 0; index < dividerDesigns.length; index++) {
                dividerDesignOptions.push(
                    new DividerDesignModel(
                        dividerDesigns[index].id,
                        dividerDesigns[index].name,
                        dividerDesigns[index].icon
                    )
                );
            }

            return dividerDesignOptions;
        }

        const getFrameFinishesData = (frameFinishes) => {
            let frameFinishOptions = [];

            for (let index = 0; index < frameFinishes.length; index++) {
                frameFinishOptions.push(
                    new FrameFinishModel(
                        frameFinishes[index].id,
                        frameFinishes[index].name,
                        frameFinishes[index].image,
                        frameFinishes[index].icon
                    )
                );
            }

            return frameFinishOptions;
        }

        const getPriceMultiplierData = (priceMultiplierData) => {
            let priceMultiplierDataModel = []

            for (let priceMultiplierDatum of priceMultiplierData) {
                priceMultiplierDataModel.push(
                    new PriceMultiplierDataModel(
                        priceMultiplierDatum.panelConfigIds,
                        priceMultiplierDatum.glassOpacityIds,
                        priceMultiplierDatum.priceMultiplier
                    )
                )
            }

            return priceMultiplierDataModel;
        }

        if (selectedDoorOption != null) {
            return new DoorModel(
                selectedDoorOption.id,
                selectedDoorOption.name,
                selectedDoorOption.image,
                getDimensionData(selectedDoorOption.dimension),
                getPanelConfigsData(selectedDoorOption.panelConfigs),
                getFrameFinishesData(selectedDoorOption.frameFinishes),
                getDividerDesignsData(selectedDoorOption.dividerDesigns),
                getGlassOpacitiesData(selectedDoorOption.glassOpacities),
                getDoorHandlingsData(selectedDoorOption.doorHandlings),
                getSwingDirectionsData(selectedDoorOption.swingDirections),
                getRoomTypeData(selectedDoorOption.roomTypes),
                getPriceMultiplierData(selectedDoorOption.priceMultiplierData)
            )
        }

        return null;
    }, []);

    useLayoutEffect(() => {
        if (selectedDoorOption != null) {
            props.setPageTitle(doorParams.name);

            const loadDoorParametersFromLocalStorage = () => {
                let localStorageData = JSON.parse(localStorage.getItem('slidingdoorco_' + doorParams.id));

                if (localStorageData != null) {
                    doorParams.setActiveDividerDesignOption(localStorageData.activeDividerDesignId);
                    doorParams.setActiveFrameFinishOption(localStorageData.activeFrameFinishId);
                    doorParams.setActiveGlassOpacityOption(localStorageData.activeGlassOpacityId);
                    doorParams.setActivePanelConfigOption(localStorageData.activePanelConfigId);
                    doorParams.setActiveRoomTypeOption(localStorageData.activeRoomTypeOptionId);
                    doorParams.getActiveRoomTypeOption().setActiveRoomTypeFlooringOption(localStorageData.activeRoomTypeFlooringOptionId);
                    doorParams.getActiveRoomTypeOption().isFurnitureVisible = localStorageData.isFurnitureVisible;
                    doorParams.setActiveDoorHandlingOption(localStorageData.activeDoorHandlingId);
                    doorParams.setActiveSwingDirectionOption(localStorageData.activeSwingDirectionId);
                    doorParams.wall.color = localStorageData.wallColor;
                    doorParams.dimension.width = localStorageData.dimensionWidth;
                    doorParams.dimension.height = localStorageData.dimensionHeight;
                }
            }

            loadDoorParametersFromLocalStorage();
        }
    }, []);

    const [doorParameters, setDoorParameters] = useState(doorParams);

    useEffect(() => {
        let activeDoorHandlingId = '';
        let activeSwingDirectionId = '';

        if (doorParameters.getActiveDoorHandlingOption() !== null) {
            activeDoorHandlingId = doorParameters.getActiveDoorHandlingOption().id
        }
        if (doorParameters.getActiveSwingDirectionOption() !== null) {
            activeSwingDirectionId = doorParameters.getActiveSwingDirectionOption().id;
        }

        let localStorageData = {
            activeDividerDesignId: doorParameters.getActiveDividerDesignOption().id,
            activeFrameFinishId: doorParameters.getActiveFrameFinishOption().id,
            activeGlassOpacityId: doorParameters.getActiveGlassOpacityOption().id,
            activePanelConfigId: doorParameters.getActivePanelConfigOption().id,
            activeDoorHandlingId: activeDoorHandlingId,
            activeSwingDirectionId: activeSwingDirectionId,
            activeRoomTypeOptionId: doorParameters.getActiveRoomTypeOption().id,
            activeRoomTypeFlooringOptionId: doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().id,
            isFurnitureVisible: doorParameters.getActiveRoomTypeOption().isFurnitureVisible,
            wallColor: doorParameters.wall.color,
            dimensionWidth: doorParameters.dimension.width,
            dimensionHeight: doorParameters.dimension.height,
        }

        localStorage.setItem('slidingdoorco_' + doorParameters.id, JSON.stringify(localStorageData));
    }, [doorParameters]);

    if (selectedDoorOption == null) {
        return <Navigate to="/"/>;
    }

    return (
        <DoorContext.Provider value={{doorParameters, setDoorParameters}}>
            <CustomizeDoorView/>
        </DoorContext.Provider>
    );
}