export default class FrameFinishModel {
    constructor(
        id: string,
        name: string,
        image: string,
        icon: string,
    ) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.icon = icon;
    }
}