import React from "react";
import {Link} from "react-router-dom";
import './index.css'

export default function DoorSelectorView(props) {
    const doorOptions = props.doorOptions;

    return (
        <>
            <div className={'bg-[#636769] p-4 text-white  hidden sm:flex flex-row justify-center gap-5 lg:gap-20'}>
                <div className={'my-auto text-center max-w-[200px]'}>
                    <div className={'font-medium'}>STEP 1</div>
                    <div className={'text-sm'}>SELECT A PRODUCT & START CUSTOMIZING</div>
                </div>
                <div className={'my-auto text-xl font-medium text-center max-w-[310px]'}>UPLOAD A PHOTO TO <span className={'text-slidingdoorco-500'}>VISUALIZE IT</span> IN YOUR SPACE</div>
                <div className={'my-auto text-center max-w-[200px]'}>
                    <div className={'font-medium'}>STEP 2</div>
                    <div className={'text-sm'}>SAVE YOUR DESIGN & REQUEST A QUOTE</div>
                </div>
            </div>

            <div className={'container mx-auto mt-4 sm:mt-6 flex flex-row gap-4 flex-wrap justify-center pb-5'}>
                {doorOptions.map((doorModel) => {
                    return (
                        <React.Fragment key={doorModel.id}>
                            <Link
                                className={'w-full sm:w-60 flex flex-row sm:flex-col gap-2 mx-4 intro-option-container'}
                                to={'/customize/' + doorModel.id} onClick={() => {
                                props.setPageTitle(doorModel.name)
                            }}>
                                <img className={'w-20 sm:w-full'} src={doorModel.image} alt={doorModel.id}/>
                                <div className={'text-xl w-fit mx-auto intro-option-title hidden sm:flex'}>
                                    {doorModel.name}
                                </div>

                                <div className={'text-xl w-fit flex flex-col justify-center sm:hidden'}>
                                    {doorModel.name}
                                </div>
                            </Link>
                        </React.Fragment>
                    );
                })}
            </div>

            <div className={'mt-auto mx-auto sm:hidden max-w-[375px]'}>
                <img className={'px-4 pb-4'} src={'/images/icons/see-it-in-your-space-mobile.gif'}  alt={'see it in your space mobile gif'}/>
            </div>
        </>
    );
}