import {useContext, useEffect} from "react";
import DropdownMenu from "./DropdownMenu";
import {DropdownMenuStructure, MenuContext} from "../index";
import {DoorContext} from "../../../../controllers/DoorController";


export default function DropdownMenuContentPanelCount(){
    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isMenuOpen, setMenuOpen} = useContext(MenuContext);

    useEffect(()=>{
        const currentWidth = doorParameters.dimension.width;
        const selectedPanelConfig = doorParameters.getActivePanelConfigOption();

        if(currentWidth < selectedPanelConfig.minWidth || currentWidth > selectedPanelConfig.maxWidth){
            for(let panelConfig of doorParameters.panelConfigs){
                if(!(currentWidth < panelConfig.minWidth || currentWidth > panelConfig.maxWidth)){
                    let clone = doorParameters.createClone();
                    clone.setActivePanelConfigOption(panelConfig.id);
                    setDoorParameters(clone);
                    return;
                }
            }
        }

    }, [doorParameters.dimension.width]);

    return (
        <>
            <DropdownMenu
                title={'panel Count'}
                contentHeight={'48px'}
                isMenuOpen={isMenuOpen.panelCount}
                setMenuOpen={() => {
                    let dropdownMenuStructure = new DropdownMenuStructure();
                    dropdownMenuStructure.panelCount = !isMenuOpen.panelCount;
                    setMenuOpen(dropdownMenuStructure);
                }}
            >
                <div className={'bg-slidingdoorco-menu-content-500 flex flex-row p-2 gap-2'}>
                    {
                        doorParameters.panelConfigs.map((panelConfig)=>{
                            return (
                                <button
                                    key={panelConfig.id}
                                    className={
                                        'px-2 py-1 rounded font-bold text-zinc-400 bg-white hover:bg-gray-300 h-fit '+
                                        (doorParameters.dimension.width < panelConfig.minWidth || doorParameters.dimension.width > panelConfig.maxWidth?'hidden':'')
                                    }
                                    onClick={()=>{
                                        let clone = doorParameters.createClone();
                                        clone.setActivePanelConfigOption(panelConfig.id);
                                        setDoorParameters(clone);
                                    }}
                                >
                                    {panelConfig.panels.length}
                                </button>
                            );
                        })
                    }
                </div>
            </DropdownMenu>
        </>
    );
}