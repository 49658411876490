export default class RoomTypeFlooringModel {
    constructor(
        id: string,
        name: string,
        roomImage: string,
        floorImage: string,
        wallImage: string,
        filterImage: string,
        furnitureImage: string,
    ) {
        this.id = id;
        this.name = name;
        this.roomImage = roomImage;
        this.floorImage = floorImage;
        this.wallImage = wallImage;
        this.filterImage = filterImage;
        this.furnitureImage = furnitureImage;
    }
}