import AnimationOptionModel from "./AnimationOptionModel";
import DoorHandlingModel from "./DoorHandlingModel";

export default class PanelModel {
    constructor(
        id: string,
        style: Object,
        animationOptions: AnimationOptionModel[],
    ) {
        const getInitialActiveId = (array) => {
            return array == null ?
                null :
                array.length === 0 ?
                    null : array[0].id
                ;
        }

        this.id = id;
        this.style = style;
        this.animationOptions = animationOptions;

        this._activeAnimationOptionId = getInitialActiveId(animationOptions)
    }

    setActiveAnimationOption = (animationOptionId: string): void => {
        this._activeAnimationOptionId = animationOptionId;
    }

    getActiveAnimationOption = ():AnimationOptionModel => {
        for (let index = 0; index < this.animationOptions.length; index++) {
            if (this._activeAnimationOptionId === this.animationOptions[index].id) {
                return this.animationOptions[index];
            }
        }

        return null;
    }
}