export default class PriceMultiplierDataModel {
    constructor(
        panelConfigIds: string[],
        glassOpacityIds: string[],
        priceMultiplier: number,
    ) {
        this.panelConfigIds = panelConfigIds;
        this.glassOpacityIds = glassOpacityIds;
        this.priceMultiplier = priceMultiplier;
    }
}