import React, {useContext, useEffect, useState, useRef} from "react"
import {DropdownMobileMenuStructure, MenuContext, ModalContext, RefContext} from "../index";
import {DoorContext} from "../../../../controllers/DoorController";
import PanelLeft from "./PanelLeft";

export default function PanelMobile() {
    const {doorParameters} = useContext(DoorContext);
    const {isMobileMenuOpen, setMobileMenuOpen} = useContext(MenuContext);
    const {setIsProductSummaryModalOpen, setIsSeeItInYourSpaceModalOpen} = useContext(ModalContext);
    const {
        doorPreviewRefHeight
    } = useContext(RefContext);


    const mobileMenuContainerRef = useRef(null);
    const step1ContainerRef = useRef(null);

    const [mobilePanelSizeWithDoorPreview, setMobilePanelSizeWithDoorPreview] = useState('');

    //use to set up animation of step 1
    useEffect(() => {
        const handleResize = () => {
            if (!isMobileMenuOpen.Step1) return;
            step1ContainerRef.current.classList.remove("h-[400px]");
            step1ContainerRef.current.classList.remove('sm:h-[252px]');
        }

        if (step1ContainerRef.current) {
            step1ContainerRef.current.addEventListener('webkitTransitionEnd', handleResize, false);
        }

        return _ => {
            if (step1ContainerRef.current) {
                step1ContainerRef.current.removeEventListener('webkitTransitionEnd', handleResize);
            }
        }

    }, [isMobileMenuOpen.Step1]);

    //use to set value of mobilePanelSizeWithDoorPreview once
    useEffect(() => {
        if (doorPreviewRefHeight === 0 || doorPreviewRefHeight === 1) return;
        if (mobilePanelSizeWithDoorPreview === '') setMobilePanelSizeWithDoorPreview('calc(100% - ' + (doorPreviewRefHeight / doorParameters.dimension.height * 135) + 'px)');
    }, [doorPreviewRefHeight]);

    return (
        <>
            <div
                className={'text-black flex flex-col p-4 gap-4 xl:hidden overflow-auto'}
                style={{
                    maxHeight: isMobileMenuOpen.Step2 || isMobileMenuOpen.Step3 ? '100%' : mobilePanelSizeWithDoorPreview,
                    scrollBehavior: 'smooth'
                }}
                ref={mobileMenuContainerRef}
            >
                <div>
                    <div
                        className={
                            'border p-2 border-[2px] border-black font-bold opacity-50 hover:opacity-100 cursor-pointer sticky ' +
                            (isMobileMenuOpen.Step1 ? 'opacity-100' : '')
                        }
                        onClick={() => {
                            let dropdownMobileMenuStructure = new DropdownMobileMenuStructure();
                            dropdownMobileMenuStructure.Step1 = !isMobileMenuOpen.Step1;
                            setMobileMenuOpen(dropdownMobileMenuStructure);
                        }}
                    >
                        Step 1: CUSTOMIZATION
                    </div>

                    <div
                        className={
                            'ml-3 overflow-hidden duration-200 transition-all ' +
                            (isMobileMenuOpen.Step1 ? 'h-[400px] sm:h-[252px]' : 'h-0')
                        }
                        ref={step1ContainerRef}
                    >
                        <div className={'flex flex-col gap-3 sm:flex-row'}>
                            <PanelLeft/>
                        </div>
                    </div>
                </div>

                <div className={'w-auto'}>
                    {/*<div*/}
                    {/*    className={*/}
                    {/*        'border p-2 border-[2px] border-black font-bold opacity-50 hover:opacity-100 cursor-pointer ' +*/}
                    {/*        (isMobileMenuOpen.Step2 ? 'opacity-100' : '')*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    Step 2: VISUALIZE IT IN YOUR SPACE*/}
                    {/*</div>*/}

                    <img
                        className={'px-3 w-auto max-w-[333px] mx-auto'}
                        src={'/images/icons/see-it-in-your-space-mobile.gif'}
                        alt={'see it in your space mobile gif'}
                        onClick={(event) => {
                            let dropdownMobileMenuStructure = new DropdownMobileMenuStructure();
                            dropdownMobileMenuStructure.Step2 = !isMobileMenuOpen.Step2;
                            setMobileMenuOpen(dropdownMobileMenuStructure);
                            setIsSeeItInYourSpaceModalOpen(true);
                        }}
                    />
                </div>

                <div>
                    <div
                        className={
                            'border p-2 border-[2px] border-black font-bold opacity-50 hover:opacity-100 cursor-pointer ' +
                            (isMobileMenuOpen.Step3 ? 'opacity-100' : '')
                        }
                        onClick={(event) => {
                            let dropdownMobileMenuStructure = new DropdownMobileMenuStructure();
                            dropdownMobileMenuStructure.Step3 = !isMobileMenuOpen.Step3;
                            setMobileMenuOpen(dropdownMobileMenuStructure);

                            setIsProductSummaryModalOpen(true);
                        }}
                    >
                        Step 2: SAVE & SEE ESTIMATE
                    </div>
                </div>

                <div
                    className={'underline xl:pt-5 text-sm'}
                >
                    <a href={'mailto:dyosupport@slidingdoorco.com'} className={'opacity-50 hover:opacity-100'}>
                        Report an error
                    </a>
                </div>
            </div>
        </>
    );
}