import React, {useContext, useEffect, useState, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {DoorContext} from "controllers/DoorController";
import {DropdownMenuStructure, MenuContext} from "../index";
import DropdownMenu from "./DropdownMenu";

export default function DropdownMenuContentSwingDirection() {
    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isMenuOpen, setMenuOpen} = useContext(MenuContext);

    return (
        doorParameters.swingDirections.length===0 ?
            <></>
            :
            <>
                <DropdownMenu
                    title={'Swing Direction'}
                    contentHeight={'128px'}
                    isMenuOpen={isMenuOpen.swingDirection}
                    setMenuOpen={() => {
                        let dropdownMenuStructure = new DropdownMenuStructure();
                        dropdownMenuStructure.swingDirection = !isMenuOpen.swingDirection;
                        setMenuOpen(dropdownMenuStructure);
                    }}
                >
                    <div className={'h-32 bg-slidingdoorco-menu-content-500 flex'}>

                        <div className={'w-[32px] flex justify-center px-2'}/>

                        <div className={'flex w-full overflow-hidden gap-2 py-2'}>
                            {
                                doorParameters.swingDirections.map((swingDirection) => {
                                    return (
                                        <React.Fragment key={swingDirection.id}>
                                            <button
                                                className={'flex flex-col justify-start w-1/2'}
                                                onClick={() => {
                                                    let clone = doorParameters.createClone();
                                                    clone.setActiveSwingDirectionOption(swingDirection.id);
                                                    setDoorParameters(clone);
                                                }}
                                            >
                                                <img
                                                    src={swingDirection.icon}
                                                    className={'mx-auto rounded-sm min-h-[90px] max-h-[90px]'}
                                                    alt={swingDirection.id}
                                                />
                                                <div
                                                    className={
                                                        'text-center text-white text-sm w-full ' +
                                                        (
                                                            doorParameters.getActiveSwingDirectionOption().id === swingDirection.id ?
                                                                'font-bold' : ''
                                                        )
                                                    }
                                                >
                                                    {swingDirection.name}
                                                </div>
                                            </button>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-[32px] flex justify-center px-2'}/>
                    </div>
                </DropdownMenu>
            </>
    );
}