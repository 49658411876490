export default class GlassOpacityModel {
    constructor(
        id: string,
        name: string,
        image: string,
        icon: string,
        style: Object,
    ) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.icon = icon;
        this.style = style;
    }
}