import RoomTypeFlooringModel from "./RoomTypeFlooringModel";

export default class RoomTypeModel {
    constructor(
        id: string,
        name: string,
        icon: string,
        roomTypeFlooringOptions: RoomTypeFlooringModel[]
    ) {
        this.id = id;
        this.name = name;
        this.icon = icon;

        this.isFurnitureVisible = true;

        this.roomTypeFlooringOptions = roomTypeFlooringOptions;

        this._activeRoomTypeFlooringOptionId =
            roomTypeFlooringOptions.length === 0 ? null : roomTypeFlooringOptions[0].id
        ;
    }

    setActiveRoomTypeFlooringOption = (roomTypeFlooringOptionId: string): void => {
        this._activeRoomTypeFlooringOptionId = roomTypeFlooringOptionId
    }

    getActiveRoomTypeFlooringOption = (): RoomTypeFlooringModel => {
        for (let index = 0; index < this.roomTypeFlooringOptions.length; index++) {
            if (this._activeRoomTypeFlooringOptionId === this.roomTypeFlooringOptions[index].id) {
                return this.roomTypeFlooringOptions[index]
            }
        }

        return null;
    }

    setNextActiveRoomTypeFlooringOption = (): void => {
        if (this.roomTypeFlooringOptions.length === 0) {
            return
        }

        let currentActiveIndex = 0;

        for (let index = 0; index < this.roomTypeFlooringOptions.length; index++) {
            if (this._activeRoomTypeFlooringOptionId === this.roomTypeFlooringOptions[index].id) {
                currentActiveIndex = index;
                break;
            }
        }

        currentActiveIndex += 1;

        if (currentActiveIndex === this.roomTypeFlooringOptions.length) {
            currentActiveIndex = 0
        }

        this.setActiveRoomTypeFlooringOption(this.roomTypeFlooringOptions[currentActiveIndex].id);
    }

    setPreviousActiveRoomTypeFlooringOption = (): void => {
        if (this.roomTypeFlooringOptions.length === 0) {
            return
        }

        let currentActiveIndex = 0;

        for (let index = 0; index < this.roomTypeFlooringOptions.length; index++) {
            if (this._activeRoomTypeFlooringOptionId === this.roomTypeFlooringOptions[index].id) {
                currentActiveIndex = index;
                break;
            }
        }

        currentActiveIndex -= 1;

        if (currentActiveIndex < 0) {
            currentActiveIndex = this.roomTypeFlooringOptions.length-1
        }

        this.setActiveRoomTypeFlooringOption(this.roomTypeFlooringOptions[currentActiveIndex].id);
    }
}