import React, {useContext} from "react";
import Wheel from "@uiw/react-color-wheel";
import Circle from "@uiw/react-color-circle";
import ShadeSlider from "@uiw/react-color-shade-slider";
import {hsvaToHex, hexToHsva} from "@uiw/color-convert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import {DoorContext} from 'controllers/DoorController'
import {DropdownMenuStructure, MenuContext, ModalContext, PanelAnimationContext} from "../index";
import DropdownMenu from "./DropdownMenu";

export default function PanelRight() {
    const {setIsSeeItInYourSpaceModalOpen, setIsProductSummaryModalOpen} = useContext(ModalContext);

    return (
        <>
            <button
                onClick={()=>{
                    setIsSeeItInYourSpaceModalOpen(true);
                }}
            >
                <img alt={'Visualize it in your Space'} src={'/images/icons/see-in-your-space.gif'} />
            </button>

            <div className={'flex-col grow justify-end hidden xl:flex mt-1'}>
                <button
                    className={'' +
                        'btn ' +
                        'border-t-0 border-b-0 border-r-0 border-l-8 border-slidingdoorco-500 rounded-none ' +
                        'hover:border-t-0 hover:border-b-0 hover:border-r-0 hover:border-l-8 hover:border-slidingdoorco-500 '+
                        'bg-slidingdoorco-header-500 '
                    }
                    onClick={()=>{
                        setIsProductSummaryModalOpen(true);
                    }}
                >
                    SAVE & SEE PRICE ESTIMATE
                    <FontAwesomeIcon className={'ml-2 text-slidingdoorco-500 font-bold'} icon={faAnglesRight}/>
                </button>
            </div>
        </>
    );
}