import React, {useContext, useEffect, useState, useRef, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faRepeat,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faDoorOpen,
    faCamera,
    faArrowRotateRight, faClose
} from "@fortawesome/free-solid-svg-icons";
import {DoorContext} from "controllers/DoorController";
import {
    EstimatedCostContext,
    MeasurementContext,
    MenuContext,
    ModalContext,
    PanelAnimationContext,
    RefContext
} from "../index";
import DoorPreview from "./DoorPreview";
import PanelMobile from "./PanelMobile";

class Color {
    constructor(r, g, b) {
        this.set(r, g, b);
    }

    toString() {
        return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)})`;
    }

    set(r, g, b) {
        this.r = this.clamp(r);
        this.g = this.clamp(g);
        this.b = this.clamp(b);
    }

    hueRotate(angle = 0) {
        angle = angle / 180 * Math.PI;
        const sin = Math.sin(angle);
        const cos = Math.cos(angle);

        this.multiply([0.213 + cos * 0.787 - sin * 0.213, 0.715 - cos * 0.715 - sin * 0.715, 0.072 - cos * 0.072 + sin * 0.928, 0.213 - cos * 0.213 + sin * 0.143, 0.715 + cos * 0.285 + sin * 0.140, 0.072 - cos * 0.072 - sin * 0.283, 0.213 - cos * 0.213 - sin * 0.787, 0.715 - cos * 0.715 + sin * 0.715, 0.072 + cos * 0.928 + sin * 0.072,]);
    }

    grayscale(value = 1) {
        this.multiply([0.2126 + 0.7874 * (1 - value), 0.7152 - 0.7152 * (1 - value), 0.0722 - 0.0722 * (1 - value), 0.2126 - 0.2126 * (1 - value), 0.7152 + 0.2848 * (1 - value), 0.0722 - 0.0722 * (1 - value), 0.2126 - 0.2126 * (1 - value), 0.7152 - 0.7152 * (1 - value), 0.0722 + 0.9278 * (1 - value),]);
    }

    sepia(value = 1) {
        this.multiply([0.393 + 0.607 * (1 - value), 0.769 - 0.769 * (1 - value), 0.189 - 0.189 * (1 - value), 0.349 - 0.349 * (1 - value), 0.686 + 0.314 * (1 - value), 0.168 - 0.168 * (1 - value), 0.272 - 0.272 * (1 - value), 0.534 - 0.534 * (1 - value), 0.131 + 0.869 * (1 - value),]);
    }

    saturate(value = 1) {
        this.multiply([0.213 + 0.787 * value, 0.715 - 0.715 * value, 0.072 - 0.072 * value, 0.213 - 0.213 * value, 0.715 + 0.285 * value, 0.072 - 0.072 * value, 0.213 - 0.213 * value, 0.715 - 0.715 * value, 0.072 + 0.928 * value,]);
    }

    multiply(matrix) {
        const newR = this.clamp(this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]);
        const newG = this.clamp(this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]);
        const newB = this.clamp(this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]);
        this.r = newR;
        this.g = newG;
        this.b = newB;
    }

    brightness(value = 1) {
        this.linear(value);
    }

    contrast(value = 1) {
        this.linear(value, -(0.5 * value) + 0.5);
    }

    linear(slope = 1, intercept = 0) {
        this.r = this.clamp(this.r * slope + intercept * 255);
        this.g = this.clamp(this.g * slope + intercept * 255);
        this.b = this.clamp(this.b * slope + intercept * 255);
    }

    invert(value = 1) {
        this.r = this.clamp((value + this.r / 255 * (1 - 2 * value)) * 255);
        this.g = this.clamp((value + this.g / 255 * (1 - 2 * value)) * 255);
        this.b = this.clamp((value + this.b / 255 * (1 - 2 * value)) * 255);
    }

    hsl() {
        // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
        const r = this.r / 255;
        const g = this.g / 255;
        const b = this.b / 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;

                case g:
                    h = (b - r) / d + 2;
                    break;

                case b:
                    h = (r - g) / d + 4;
                    break;
                default:
                    h = 0;
                    break;
            }
            h /= 6;
        }

        return {
            h: h * 100, s: s * 100, l: l * 100,
        };
    }

    clamp(value) {
        if (value > 255) {
            value = 255;
        } else if (value < 0) {
            value = 0;
        }
        return value;
    }
}

class ColorToFilterSolver {
    constructor(target, baseColor) {
        this.target = target;
        this.targetHSL = target.hsl();
        this.reusedColor = new Color(0, 0, 0);
    }

    solve() {
        const result = this.solveNarrow(this.solveWide());
        return {
            values: result.values, loss: result.loss, filter: this.css(result.values),
        };
    }

    solveWide() {
        const A = 5;
        const c = 15;
        const a = [60, 180, 18000, 600, 1.2, 1.2];

        let best = {loss: Infinity};
        for (let i = 0; best.loss > 25 && i < 3; i++) {
            const initial = [50, 20, 3750, 50, 100, 100];
            const result = this.spsa(A, a, c, initial, 1000);
            if (result.loss < best.loss) {
                best = result;
            }
        }
        return best;
    }

    solveNarrow(wide) {
        const A = wide.loss;
        const c = 2;
        const A1 = A + 1;
        const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
        return this.spsa(A, a, c, wide.values, 500);
    }

    spsa(A, a, c, values, iters) {
        const alpha = 1;
        const gamma = 0.16666666666666666;

        let best = null;
        let bestLoss = Infinity;
        const deltas = new Array(6);
        const highArgs = new Array(6);
        const lowArgs = new Array(6);

        for (let k = 0; k < iters; k++) {
            const ck = c / Math.pow(k + 1, gamma);
            for (let i = 0; i < 6; i++) {
                deltas[i] = Math.random() > 0.5 ? 1 : -1;
                highArgs[i] = values[i] + ck * deltas[i];
                lowArgs[i] = values[i] - ck * deltas[i];
            }

            const lossDiff = this.loss(highArgs) - this.loss(lowArgs);
            for (let i = 0; i < 6; i++) {
                const g = lossDiff / (2 * ck) * deltas[i];
                const ak = a[i] / Math.pow(A + k + 1, alpha);
                values[i] = fix(values[i] - ak * g, i);
            }

            const loss = this.loss(values);
            if (loss < bestLoss) {
                best = values.slice(0);
                bestLoss = loss;
            }
        }
        return {values: best, loss: bestLoss};

        function fix(value, idx) {
            let max = 100;
            if (idx === 2 /* saturate */) {
                max = 7500;
            } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
                max = 200;
            }

            if (idx === 3 /* hue-rotate */) {
                if (value > max) {
                    value %= max;
                } else if (value < 0) {
                    value = max + value % max;
                }
            } else if (value < 0) {
                value = 0;
            } else if (value > max) {
                value = max;
            }
            return value;
        }
    }

    loss(filters) {
        // Argument is array of percentages.
        const color = this.reusedColor;
        color.set(0, 0, 0);

        color.invert(filters[0] / 100);
        color.sepia(filters[1] / 100);
        color.saturate(filters[2] / 100);
        color.hueRotate(filters[3] * 3.6);
        color.brightness(filters[4] / 100);
        color.contrast(filters[5] / 100);

        const colorHSL = color.hsl();
        return (Math.abs(color.r - this.target.r) + Math.abs(color.g - this.target.g) + Math.abs(color.b - this.target.b) + Math.abs(colorHSL.h - this.targetHSL.h) + Math.abs(colorHSL.s - this.targetHSL.s) + Math.abs(colorHSL.l - this.targetHSL.l));
    }

    css(filters) {
        function fmt(idx, multiplier = 1) {
            return Math.round(filters[idx] * multiplier);
        }

        return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%)`;
    }
}

function getWallFilter(hexColor) {
    function hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16),] : null;
    }

    const rgb = hexToRgb(hexColor);
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new ColorToFilterSolver(color);
    const result = solver.solve();

    if (result.loss > 5) {
        return getWallFilter(hexColor);
    }

    return result.filter;
}

function getClipPathWidthStart(width) {
    const maxClipPathWidth = 96.35;
    const maxDimensionWidth = 288;
    const maxPercentageInsideBox = maxClipPathWidth - (100 - maxClipPathWidth);

    const percentageInsideBox = maxPercentageInsideBox * width / maxDimensionWidth
    return (100 - percentageInsideBox) / 2;
}

function getClipPathWidthEnd(width) {
    const maxClipPathWidth = 96.35;
    const maxDimensionWidth = 288;
    const maxPercentageInsideBox = maxClipPathWidth - (100 - maxClipPathWidth);

    const percentageInsideBox = maxPercentageInsideBox * width / maxDimensionWidth
    const percentageOnSideOfBox = (100 - percentageInsideBox) / 2;

    return percentageOnSideOfBox + percentageInsideBox;
}

function getClipPathHeight(height) {
    const maxClipPathHeight = 100 - 75.7;
    const maxDimensionHeight = 120;

    return (100 - (maxClipPathHeight * (height / maxDimensionHeight)));
}

export default function MainContent() {
    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isCheckedHumanScale, isCheckedRuler} = useContext(MeasurementContext);
    const {setEstimatedCost} = useContext(EstimatedCostContext);
    const {panelAnimationIndex, setPanelAnimationIndex} = useContext(PanelAnimationContext);
    const {setIsSeeItInYourSpaceModalOpen} = useContext(ModalContext);
    const {isMobileMenuOpen} = useContext(MenuContext);
    const {doorPreviewRefContainerHeight, setDoorPreviewRefContainerHeight} = useContext(RefContext);

    const [wallFilter, setWallFilter] = useState('');

    const [wallClipPathWidthStart, setWallClipPathWidthStart] = useState(0);
    const [wallClipPathWidthEnd, setWallClipPathWidthEnd] = useState(0);
    const [wallClipPathHeight, setWallClipPathHeight] = useState(0);

    const [zoomLevel, setZoomLevel] = useState(1);

    const [isStartOverModalOpen, setIsStartOverModalOpen] = useState(false);

    const refWallFilter = useRef(null);

    const onClickStartOver = () => {
        const doorParameterClone = doorParameters.createClone();
        doorParameterClone.wall.color = '#fff';
        doorParameterClone.dimension.width = doorParameters.dimension.defaultWidth;
        doorParameterClone.dimension.height = doorParameters.dimension.defaultHeight;

        doorParameterClone.setActivePanelConfigOption(doorParameterClone.panelConfigs[0].id);

        if (doorParameterClone.dimension.width < doorParameterClone.getActivePanelConfigOption().minWidth || doorParameterClone.dimension.width > doorParameterClone.getActivePanelConfigOption().maxWidth) {
            for (let panelConfig of doorParameters.panelConfigs) {
                if (!(doorParameterClone.dimension.width < panelConfig.minWidth || doorParameterClone.dimension.width > panelConfig.maxWidth)) {
                    let clone = doorParameters.createClone();
                    doorParameterClone.setActivePanelConfigOption(panelConfig.id);
                    setDoorParameters(clone);
                    break;
                }
            }
        }

        doorParameterClone.setActiveFrameFinishOption(doorParameterClone.frameFinishes[0].id);
        doorParameterClone.setActiveDividerDesignOption(doorParameterClone.dividerDesigns[0].id);
        doorParameterClone.setActiveGlassOpacityOption(doorParameterClone.glassOpacities[0].id);
        doorParameterClone.setActiveRoomTypeOption(doorParameterClone.roomTypeOptions[0].id);
        doorParameterClone.getActiveRoomTypeOption()
            .setActiveRoomTypeFlooringOption(doorParameterClone.getActiveRoomTypeOption().roomTypeFlooringOptions[0].id);
        doorParameterClone.getActiveRoomTypeOption().isFurnitureVisible = true;

        if (doorParameterClone.doorHandlings.length !== 0) doorParameterClone.setActiveDoorHandlingOption(doorParameterClone.doorHandlings[0].id);
        if (doorParameterClone.swingDirections.length !== 0) doorParameterClone.setActiveSwingDirectionOption(doorParameterClone.swingDirections[0].id);

        setDoorParameters(doorParameterClone);
        setPanelAnimationIndex(0);
    };

    useEffect(() => {
        const handleResize = () => {
            setDoorPreviewRefContainerHeight(refWallFilter.current.height);
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    useEffect(() => {
        setWallClipPathWidthStart(getClipPathWidthStart(doorParameters.dimension.width));
        setWallClipPathWidthEnd(getClipPathWidthEnd(doorParameters.dimension.width));
    }, [doorParameters.dimension.width]);

    useEffect(() => {
        setWallClipPathHeight(getClipPathHeight(doorParameters.dimension.height));
    }, [doorParameters.dimension.height]);

    useEffect(() => {
        setWallFilter(getWallFilter(doorParameters.wall.color));
    }, [doorParameters.wall.color]);

    useEffect(() => {
        for (let priceMultiplierDatum of doorParameters.priceMultiplierData) {
            if (priceMultiplierDatum.glassOpacityIds.includes(doorParameters.getActiveGlassOpacityOption().id) && priceMultiplierDatum.panelConfigIds.includes(doorParameters.getActivePanelConfigOption().id)) {
                let newCost = doorParameters.dimension.width * doorParameters.dimension.height * priceMultiplierDatum.priceMultiplier;

                newCost = Math.round(newCost * 100) / 100;

                setEstimatedCost(newCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                return;
            }
        }

        setEstimatedCost(0);

    }, [doorParameters.dimension.width, doorParameters.dimension.height, doorParameters.getActivePanelConfigOption(), doorParameters.getActiveGlassOpacityOption()]);


    return (<>
        <div className={'grow flex flex-col-reverse overflow-hidden'}>
            <div
                className={
                    'flex flex-col justify-start overflow-hidden duration-100 transition-all ' +
                    (isMobileMenuOpen.Step1 ? 'h-0' : 'h-1/6 md:h-1/6 lg:h-1/6 xl:h-2/6 2xl:h-1/6')
                }
                onClick={() => {
                    setPanelAnimationIndex(panelAnimationIndex + 1);
                }}
                style={{
                    transformOrigin: 'top',
                    transform: 'scale(' + zoomLevel + ')'
                }}
            >
                <div className={'relative'}>
                    <img
                        src={doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().floorImage}
                        alt={'Floor image'}
                        className={'absolute top-0 w-full'}
                    />
                </div>

            </div>

            <div
                className={'grow flex flex-col justify-end duration-100 transition-all'}
                onClick={() => {
                    setPanelAnimationIndex(panelAnimationIndex + 1);
                }}
                style={{
                    transformOrigin: 'bottom',
                    transform: 'scale(' + zoomLevel + ')',
                }}
            >
                <div className={'absolute w-full h-0'}>
                    <div
                        style={{
                            backgroundColor: doorParameters.wall.color,
                            filter: 'brightness(30%)',
                            height: '1px'
                        }}
                    />
                </div>
                <div className={'relative h-full'}>
                    <div className={'relative h-full overflow-hidden'}>
                        <img
                            src={doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().roomImage}
                            alt={'Room image'}
                            className={'bottom-0 w-full absolute'}
                        />

                        <img
                            src={doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().furnitureImage}
                            alt={'Furniture image'}
                            className={'absolute bottom-0 w-full ' + (doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().furnitureImage === '' ? 'hidden' : doorParameters.getActiveRoomTypeOption().isFurnitureVisible ? '' : 'hidden')}
                        />

                        <img
                            src={doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().wallImage}
                            alt={'Wall image'}
                            className={'absolute bottom-0 w-full'}
                            style={{
                                clipPath: 'polygon(' + '0 100%, ' + '0 0, ' + '100% 0, ' + '100% 100%, ' + wallClipPathWidthEnd + '% 100%, ' + wallClipPathWidthEnd + '% ' + wallClipPathHeight + '%, ' + wallClipPathWidthStart + '% ' + wallClipPathHeight + '%, ' + wallClipPathWidthStart + '% 100%' + ')',
                            }}
                        />

                        <img
                            ref={refWallFilter}
                            src={doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().filterImage}
                            alt={'Wall image Filter'}
                            className={'absolute bottom-0 w-full mix-blend-multiply'}
                            style={{
                                clipPath: 'polygon(' + '0 100%, ' + '0 0, ' + '100% 0, ' + '100% 100%, ' + wallClipPathWidthEnd + '% 100%, ' + wallClipPathWidthEnd + '% ' + wallClipPathHeight + '%, ' + wallClipPathWidthStart + '% ' + wallClipPathHeight + '%, ' + wallClipPathWidthStart + '% 100%' + ')',
                                filter: wallFilter,
                            }}
                            onLoad={() => {
                                setDoorPreviewRefContainerHeight(refWallFilter.current.height);
                            }}
                        />
                    </div>

                    <DoorPreview
                        panelAnimationIndex={panelAnimationIndex}
                        setPanelAnimationIndex={setPanelAnimationIndex}
                        containerHeight={doorPreviewRefContainerHeight}
                        width={'calc(1px + ' + (wallClipPathWidthEnd - wallClipPathWidthStart) + '%)'}
                        height={('calc(1px + ' + doorPreviewRefContainerHeight * (100 - wallClipPathHeight) / 100) + 'px)'}
                    />

                    {isCheckedRuler ? <>
                        <img
                            src={'/images/ruler/ruler.png'}
                            alt={'Ruler'}
                            className={'absolute bottom-0 w-full'}
                            style={{
                                bottom: doorPreviewRefContainerHeight * -4 * .0005 + 'px'
                            }}
                        />
                    </> : <></>}

                    {isCheckedHumanScale ? <img
                        src={'/images/ruler/human_scale.png'}
                        alt={'Human Scale'}
                        className={'absolute bottom-0 w-full'}
                        style={{
                            bottom: doorPreviewRefContainerHeight * -20 * .0005 + 'px'
                        }}
                    /> : <></>}
                </div>

            </div>

            <div className={'h-0 flex justify-end '}>
                <div className={'flex w-fit p-2 text-white gap-2 justify-end'}>
                    <button className={'drop-shadow-lg w-8 h-8 justify-center bg-gray-500 rounded-full scale-x-[-1]'}
                            onClick={() => {
                                if (zoomLevel + 0.2 < 1.6) setZoomLevel(zoomLevel + 0.2);
                            }}>
                        <div><FontAwesomeIcon icon={faMagnifyingGlassPlus}/></div>
                    </button>

                    <button className={'drop-shadow-lg w-8 h-8 justify-center bg-gray-500 rounded-full scale-x-[-1]'}
                            onClick={() => {
                                if (zoomLevel !== 1) setZoomLevel(zoomLevel - 0.2);
                            }}>
                        <div><FontAwesomeIcon icon={faMagnifyingGlassMinus}/></div>
                    </button>
                </div>
            </div>

            <div className={'h-0 flex justify-end'}>
                <div className={'flex w-fit py-2 pr-2 text-white gap-2 justify-end mt-10'}>
                    <button className={'drop-shadow-lg h-8 justify-center bg-gray-500 rounded-l-full'}
                            onClick={()=>{
                                setPanelAnimationIndex(panelAnimationIndex + 1);
                            }}
                    >
                        <div className={'flex flex-row justify-center gap-2 px-2'}>
                            <div className={'text-sm'}>Click to Open</div>
                            <FontAwesomeIcon icon={faDoorOpen} className={'my-auto'}/>
                        </div>
                    </button>
                </div>
            </div>

            <div className={'h-0 ' + (isMobileMenuOpen.Step1 ? 'hidden' : '')}>
                <div className={'flex w-fit p-2 text-white gap-2 justify-end'}>
                    <button className={'drop-shadow-lg w-8 h-8 justify-center bg-gray-500 rounded-full'}
                            onClick={() => {
                                setIsStartOverModalOpen(true);
                            }}>
                        <div><FontAwesomeIcon icon={faArrowRotateRight}/></div>
                    </button>
                </div>
            </div>
        </div>

        <div className={'h-0 hidden xl:flex '}>
            <div className={'relative flex justify-center bg-slidingdoorco-header-500/[.75] w-full'}
                 style={{marginTop: '-48px'}}>
                <div
                    className={'flex text-white my-auto font-bold border-slidingdoorco-header-100 hover:border-white cursor-pointer border-2 py-1 px-4 gap-2'}
                    onClick={() => {
                        setIsSeeItInYourSpaceModalOpen(true);
                    }}
                >
                    <FontAwesomeIcon icon={faCamera} className={'my-auto'}/>
                    VISUALIZE IT IN YOUR SPACE
                </div>
            </div>
        </div>

        <div
            className={
                isStartOverModalOpen ?
                    'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10 ' :
                    'hidden'
            }
            style={{
                backgroundColor: 'rgba(0,0,0,.75)',
                transition: 'visibility 0s linear 0s, opacity .2 0s'
            }}
        >
            <div className={'flex flex-col w-80 gap-3 bg-white p-2 my-auto mx-auto gap-2'} style={{maxWidth: '450px'}}>
                <div className={'flex justify-end'}>
                    <button className={'flex flex-row justify-center gap-2 hover:bg-gray-300 w-5 h-5'} onClick={() => {
                        setIsStartOverModalOpen(false);
                    }}>
                        <div><FontAwesomeIcon icon={faClose}/></div>
                    </button>
                </div>
                <div className={'flex justify-center'}>Would you like to start over?</div>
                <div className={'flex justify-center mb-2'}>
                    <button
                        className={'font-bold underline'}
                        onClick={()=>{
                            onClickStartOver();
                            window.location.replace('/');
                        }}
                    >
                        START OVER
                    </button>
                </div>

            </div>
        </div>
    </>);
}
