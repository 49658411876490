import React, {useContext, useEffect, useState, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {DoorContext} from "controllers/DoorController";
import {DropdownMenuStructure, MenuContext} from "../index";
import DropdownMenu from "./DropdownMenu";

export default function DropdownMenuContentDoorHandling() {
    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isMenuOpen, setMenuOpen} = useContext(MenuContext);

    return (
        doorParameters.doorHandlings.length!==0 && doorParameters.getActivePanelConfigOption().panels.length===1 ?
            <>
                <DropdownMenu
                    title={'Door Handling'}
                    contentHeight={'128px'}
                    isMenuOpen={isMenuOpen.doorHandling}
                    setMenuOpen={() => {
                        let dropdownMenuStructure = new DropdownMenuStructure();
                        dropdownMenuStructure.doorHandling = !isMenuOpen.doorHandling;
                        setMenuOpen(dropdownMenuStructure);
                    }}
                >
                    <div className={'h-32 bg-slidingdoorco-menu-content-500 flex'}>

                        <div className={'w-[32px] flex justify-center px-2'}/>

                        <div className={'flex w-full overflow-hidden gap-2 py-2'}>
                            {
                                doorParameters.doorHandlings.map((doorHandling) => {
                                    return (
                                        <React.Fragment key={doorHandling.id}>
                                            <button
                                                className={'flex flex-col justify-start w-1/2'}
                                                onClick={() => {
                                                    let clone = doorParameters.createClone();
                                                    clone.setActiveDoorHandlingOption(doorHandling.id);
                                                    setDoorParameters(clone);
                                                }}
                                            >
                                                <img
                                                    src={doorHandling.icon}
                                                    className={'mx-auto rounded-sm min-h-[90px] max-h-[90px]'}
                                                    alt={doorHandling.id}
                                                />
                                                <div
                                                    className={
                                                        'text-center text-white text-sm w-full ' +
                                                        (
                                                            doorParameters.getActiveDoorHandlingOption().id === doorHandling.id ?
                                                                'font-bold' : ''
                                                        )
                                                    }
                                                >
                                                    {doorHandling.name}
                                                </div>
                                            </button>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>

                        <div className={'w-[32px] flex justify-center px-2'}/>
                    </div>
                </DropdownMenu>
            </>
            :
            <></>
    );
}