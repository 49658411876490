import Routes from 'config/Routes'
import {useEffect} from "react";

function App() {
    useEffect(() => {
        const handleResize = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--vh', (window.innerHeight * .01) + 'px');
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    return (
        <>
            <Routes/>
        </>
    );
}

export default App;
