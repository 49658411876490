import React, {useContext, useState} from "react";
import "../index.css"
import {MenuContext, ModalContext, PanelAnimationContext} from "../index";
import {MeasurementContext} from "../index";
import {DropdownMenuStructure} from "../index"
import DropdownMenu from "./DropdownMenu";
import DropdownMenuContentDimensions from './DropdownMenuContentDimensions'
import DropdownMenuContentGlassOpacity from "./DropdownMenuContentGlassOpacity";
import DropdownMenuContentFrameFinish from "./DropdownMenuContentFrameFinish";
import DropdownMenuContentDividerDesign from "./DropdownMenuContentDividerDesign";
import {DoorContext} from "../../../../controllers/DoorController";
import DropdownMenuContentPanelCount from "./DropdownMenuContentPanelCount";
import DropdownMenuContentDoorHandling from "./DropdownMenuContentDoorHandling";
import DropdownMenuContentSwingDirection from "./DropdownMenuContentSwingDirection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAnglesRight, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import Wheel from "@uiw/react-color-wheel";
import Circle from "@uiw/react-color-circle";
import ShadeSlider from "@uiw/react-color-shade-slider";
import {hexToHsva, hsvaToHex} from "@uiw/color-convert";

export default function PanelLeft() {
    const {
        isCheckedHumanScale, setCheckedHumanScale,
        isCheckedRuler, setCheckedRuler,
    } = useContext(MeasurementContext);

    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isMenuOpen, setMenuOpen} = useContext(MenuContext);
    const {panelAnimationIndex, setPanelAnimationIndex} = useContext(PanelAnimationContext);
    const {setIsProductSummaryModalOpen} = useContext(ModalContext);

    return (<>
        <div>
            <div className={'min-w-[270px]'} />
            <div className={'w-full h-2 bg-black hidden xl:flex'}/>
            <div className={'text-base font-extrabold mb-2 hidden xl:flex'}>CUSTOMIZATION</div>

            <DropdownMenuContentDimensions/>

            <DropdownMenuContentPanelCount/>

            <DropdownMenuContentFrameFinish/>

            <DropdownMenuContentDividerDesign/>

            <DropdownMenuContentGlassOpacity/>

            <DropdownMenuContentDoorHandling/>

            <DropdownMenuContentSwingDirection/>

            {
                doorParameters.id === 'swing_doors' ?
                    <>
                        <br/>
                        <div className={'text-xs flex py-1'}>
                            <div className={'px-1'}>*</div>
                            <div className={'grow'}>
                                Placeholder handles have been included for display purposes. A wide variety of locks &
                                handles are available in the sales process.
                            </div>
                        </div>
                    </> : <></>
            }

            <br/>
            <div className={'mt-2 flex form-control'}>
                <label className="cursor-pointer label p-0">
                    <span className="w-1/2 label-text text-sm font-bold">HUMAN SCALE</span>
                    <div className={'flex w-1/2'}>
                        <input className="toggle toggle-sm toggle-primary" type="checkbox"
                               checked={isCheckedHumanScale} onChange={() => {
                            setCheckedHumanScale(!isCheckedHumanScale);
                        }}/>
                    </div>
                </label>
            </div>
            <div className={'mt-2 flex form-control'}>
                <label className="cursor-pointer label p-0">
                    <span className="w-1/2 label-text text-sm font-bold">RULER</span>
                    <div className={'flex w-1/2'}>
                        <input className="toggle toggle-sm toggle-primary" type="checkbox"
                               checked={isCheckedRuler} onChange={() => {
                            setCheckedRuler(!isCheckedRuler);
                        }}/>
                    </div>
                </label>
            </div>
            <div className={'mt-2 flex form-control'}>
                <label className="cursor-pointer label p-0">
                    <span className="w-1/2 label-text text-sm font-bold">OPEN</span>
                    <div className={'flex w-1/2'}>
                        <input
                            className="toggle toggle-sm toggle-primary" type="checkbox"
                            checked={panelAnimationIndex % doorParameters.getActivePanelConfigOption().panels[0].getActiveAnimationOption().animation.length !== 0}
                            onChange={() => {
                                panelAnimationIndex % doorParameters.getActivePanelConfigOption().panels[0].getActiveAnimationOption().animation.length === 0 ?
                                    setPanelAnimationIndex(1) :
                                    setPanelAnimationIndex(0)
                                ;
                            }}/>
                    </div>
                </label>
            </div>
        </div>

        <div className={'w-full'} />

        <div>
            <div className={'min-w-[270px]'}/>
            <div className={'w-full xl:w-3/4 h-2 bg-black flex sm:hidden xl:flex'}/>
            <div className={'text-base font-extrabold mb-2 flex sm:hidden xl:flex xl:flex'}>EDIT SETTING</div>

            <DropdownMenu
                title={'room Type'}
                contentHeight={
                    doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().furnitureImage ?
                        '248px' : '208px'
                }
                isMenuOpen={isMenuOpen.roomType}
                setMenuOpen={() => {
                    let dropdownMenuStructure = new DropdownMenuStructure();
                    dropdownMenuStructure.roomType = !isMenuOpen.roomType;
                    setMenuOpen(dropdownMenuStructure);
                }}
            >
                <div className={'bg-slidingdoorco-menu-content-500 flex flex-col'}>
                    <div className={'flex justify-center h-7'}>
                        <button onClick={() => {
                            let cloneDoorParameters = doorParameters.createClone();
                            cloneDoorParameters.setPreviousActiveRoomTypeOption();
                            setDoorParameters(cloneDoorParameters);
                        }}>
                            <FontAwesomeIcon icon={faAngleUp}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>
                    <div className={'grow flex flex-col'}>
                        <div className={'grow flex flex-row justify-center '}>
                            <img className={'h-[132px]'} src={doorParameters.getActiveRoomTypeOption().icon}/>
                        </div>
                        <div
                            className={'text-center text-white text-sm'}>{doorParameters.getActiveRoomTypeOption().name}</div>
                    </div>
                    <div className={'flex justify-center h-7'}>
                        <button onClick={() => {
                            let cloneDoorParameters = doorParameters.createClone();
                            cloneDoorParameters.setNextActiveRoomTypeOption();
                            setDoorParameters(cloneDoorParameters);
                        }}>
                            <FontAwesomeIcon icon={faAngleDown}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>
                </div>
                <div className={'py-2 flex flex-row'}>
                    <div>
                        Furniture:
                    </div>
                    <div className={'flex flex-col justify-center'}>
                        <input
                            type="checkbox"
                            className="toggle toggle-sm toggle-primary ml-2"
                            checked={doorParameters.getActiveRoomTypeOption().isFurnitureVisible}
                            onChange={() => {
                                let clone = doorParameters.createClone();
                                clone.getActiveRoomTypeOption().isFurnitureVisible =
                                    !doorParameters.getActiveRoomTypeOption().isFurnitureVisible
                                ;
                                setDoorParameters(clone);
                            }}
                        />
                    </div>
                </div>
            </DropdownMenu>

            <DropdownMenu
                title={'Wall Color'}
                contentHeight={'304px'}
                isMenuOpen={isMenuOpen.wallColor}
                setMenuOpen={() => {
                    let dropdownMenuStructure = new DropdownMenuStructure();
                    dropdownMenuStructure.wallColor = !isMenuOpen.wallColor;
                    setMenuOpen(dropdownMenuStructure);
                }}
            >
                <div className={'bg-slidingdoorco-menu-content-500 p-2 flex flex-col gap-1'}>
                    {/*documentation for color picker: https://uiwjs.github.io/react-color/*/}
                    <div className={'flex justify-center'}>
                        <Wheel
                            color={doorParameters.wall.color}
                            onChange={(color) => {
                                let clone = doorParameters.createClone();
                                clone.wall.color = color.hex;
                                setDoorParameters(clone);
                            }}
                        />
                    </div>
                    <div className={'flex justify-center pt-2'}>
                        <Circle
                            colors={['#fff', '#8D99AE', '#2B2D42']}
                            color={doorParameters.wall.color}
                            onChange={(color) => {
                                let clone = doorParameters.createClone();
                                clone.wall.color = color.hex;
                                setDoorParameters(clone);
                            }}
                        />
                    </div>
                    <div className={'text-white text-sm -mt-2'}>Lightness</div>
                    <div className={'px-4'}>
                        <ShadeSlider
                            hsva={hexToHsva(doorParameters.wall.color)}
                            onChange={(newShade) => {
                                let clone = doorParameters.createClone();
                                clone.wall.color = hsvaToHex({...hexToHsva(doorParameters.wall.color), v: newShade.v});
                                setDoorParameters(clone);
                            }}
                        />
                    </div>
                </div>
            </DropdownMenu>

            <DropdownMenu
                title={'flooring'}
                contentHeight={'208px'}
                isMenuOpen={isMenuOpen.flooring}
                setMenuOpen={() => {
                    let dropdownMenuStructure = new DropdownMenuStructure();
                    dropdownMenuStructure.flooring = !isMenuOpen.flooring;
                    setMenuOpen(dropdownMenuStructure);
                }}
            >
                <div className={'bg-slidingdoorco-menu-content-500 flex flex-col'}>
                    <div className={'flex justify-center h-7'}>
                        <button onClick={() => {
                            let cloneDoorParameters = doorParameters.createClone();
                            cloneDoorParameters.getActiveRoomTypeOption().setPreviousActiveRoomTypeFlooringOption();
                            setDoorParameters(cloneDoorParameters);
                        }}>
                            <FontAwesomeIcon icon={faAngleUp}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>
                    <div className={'grow flex flex-col'}>
                        <div className={'grow flex flex-row justify-center '}>
                            <img
                                className={'h-[132px]'}
                                src={doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().floorImage}
                                alt={doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().name}
                            />
                        </div>
                        <div className={'text-center text-white text-sm'}>
                            {doorParameters.getActiveRoomTypeOption().getActiveRoomTypeFlooringOption().name}
                        </div>
                    </div>
                    <div className={'flex justify-center h-7'}>
                        <button onClick={() => {
                            let cloneDoorParameters = doorParameters.createClone();
                            cloneDoorParameters.getActiveRoomTypeOption().setNextActiveRoomTypeFlooringOption();
                            setDoorParameters(cloneDoorParameters);
                        }}>
                            <FontAwesomeIcon icon={faAngleDown}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>
                </div>
            </DropdownMenu>
        </div>

        <div className={'grow hidden xl:flex flex-col justify-end gap-2 mt-2'}>
            <div>
                <a
                    href={'mailto:dyosupport@slidingdoorco.com'}
                    className={'underline text-xs opacity-50 hover:opacity-100'}
                >
                    REPORT AN ERROR
                </a>
            </div>
            <button
                className={'' +
                    'btn ' +
                    'border-t-0 border-b-0 border-r-0 border-l-8 border-slidingdoorco-500 rounded-none ' +
                    'hover:border-t-0 hover:border-b-0 hover:border-r-0 hover:border-l-8 hover:border-slidingdoorco-500 '+
                    'bg-slidingdoorco-header-500 '
                }
                onClick={()=>{
                    setIsProductSummaryModalOpen(true);
                }}
            >
                SAVE & SEE PRICE ESTIMATE
                <FontAwesomeIcon className={'ml-2 text-slidingdoorco-500 font-bold'} icon={faAnglesRight}/>
            </button>
        </div>
    </>);
}