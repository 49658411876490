import React, {useState, useEffect, createContext, useRef} from "react";
import 'react-tiny-fab/dist/styles.css';

import PanelLeft from './components/PanelLeft'
import PanelRight from './components/PanelRight'
import MainContent from './components/MainContent'
import ModalGetAQuote from "./components/ModalGetAQuote";
import ModalSeeItInYourSpace from "./components/ModalSeeItInYourSpace";
import PanelMobile from "./components/PanelMobile";
import ModalProductSummary from "./components/ModalProductSummary";


export const MenuContext = createContext(null);
export const MeasurementContext = createContext(null);
export const ModalContext = createContext(null);
export const RefContext = createContext(null);
export const EstimatedCostContext = createContext(null);
export const PanelAnimationContext = createContext(null);
export const SeeItInYourSpaceContext = createContext(null);

export function DropdownMenuStructure(
    dimensions = false,
    panelCount = false,
    frameFinish = false,
    dividerDesign = false,
    glassOpacity = false,
    doorHandling = false,
    swingDirection = false,
    roomType = false,
    wallColor = false,
    flooring = false,
) {
    return {
        dimensions: dimensions,
        panelCount: panelCount,
        frameFinish: frameFinish,
        dividerDesign: dividerDesign,
        glassOpacity: glassOpacity,
        doorHandling: doorHandling,
        swingDirection: swingDirection,
        roomType: roomType,
        wallColor: wallColor,
        flooring: flooring,
    }
}

export function DropdownMobileMenuStructure(
    Step1 = false,
    Step2 = false,
    Step3 = false,
) {
    return {
        Step1: Step1,
        Step2: Step2,
        Step3: Step3,
    }
}

export default function CustomizeDoorView() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(DropdownMobileMenuStructure());
    const [isMenuOpen, setMenuOpen] = useState(DropdownMenuStructure());
    const [isCheckedHumanScale, setCheckedHumanScale] = useState(false);
    const [isCheckedRuler, setCheckedRuler] = useState(false);
    const [isSeeItInYourSpaceModalOpen, setIsSeeItInYourSpaceModalOpen] = useState(false);
    const [isGetAQuoteModalOpen, setIsGetAQuoteModalOpen] = useState(false);
    const [isProductSummaryModalOpen, setIsProductSummaryModalOpen] = useState(false);

    const [estimatedCost, setEstimatedCost] = useState(0);

    const [seeItInYourSpaceGeneratedImage, setSeeItInYourSpaceGeneratedImage] = useState('');

    const [panelAnimationIndex, setPanelAnimationIndex] = useState(0);

    const doorPreviewRef = useRef(null);
    const [doorPreviewRefWidth, setDoorPreviewRefWidth] = useState(0);
    const [doorPreviewRefHeight, setDoorPreviewRefHeight] = useState(0);
    const [doorPreviewRefContainerHeight, setDoorPreviewRefContainerHeight] = useState(0);

    useEffect(() => {
        setMenuOpen(DropdownMenuStructure());
    }, [isSeeItInYourSpaceModalOpen, isGetAQuoteModalOpen]);

    useEffect(()=>{
        document.body.style.overflow='hidden';
        document.body.style.overscrollBehavior='none';

        return _ => {
            document.body.style.overflow='auto';
            document.body.style.overscrollBehavior='auto';
        }
    }, []);

    return (
        <SeeItInYourSpaceContext.Provider value={{seeItInYourSpaceGeneratedImage, setSeeItInYourSpaceGeneratedImage}}>
            <PanelAnimationContext.Provider value={{panelAnimationIndex, setPanelAnimationIndex}}>
                <EstimatedCostContext.Provider value={{estimatedCost, setEstimatedCost}}>
                    <MenuContext.Provider value={{isMenuOpen, setMenuOpen, isMobileMenuOpen, setMobileMenuOpen}}>
                        <MeasurementContext.Provider value={{
                            isCheckedHumanScale,
                            setCheckedHumanScale,
                            isCheckedRuler,
                            setCheckedRuler
                        }}>
                            <RefContext.Provider value={{
                                doorPreviewRef,
                                doorPreviewRefWidth, setDoorPreviewRefWidth,
                                doorPreviewRefHeight, setDoorPreviewRefHeight,
                                doorPreviewRefContainerHeight, setDoorPreviewRefContainerHeight
                            }}>
                                <ModalContext.Provider value={{
                                    isSeeItInYourSpaceModalOpen,
                                    setIsSeeItInYourSpaceModalOpen,
                                    isGetAQuoteModalOpen,
                                    setIsGetAQuoteModalOpen,
                                    isProductSummaryModalOpen, setIsProductSummaryModalOpen
                                }}>

                                    <div
                                        className={'w-screen mx-auto xl:p-2 flex gap-2 grow max-w-[1920px] overflow-hidden'}>
                                        <div
                                            className={'w-1/4 flex-col hidden xl:flex max-w-[316px] gap-3 overflow-hidden hover:overflow-auto'}>
                                            <PanelLeft/>
                                        </div>
                                        <div className={'grow flex flex-col overflow-hidden'}>
                                            <MainContent/>
                                            <PanelMobile/>
                                        </div>
                                        <div className={'w-1/4 flex-col hidden xl:flex max-w-[316px] gap-3 overflow-hidden hover:overflow-auto'}>
                                            <PanelRight/>
                                        </div>
                                    </div>

                                    <ModalSeeItInYourSpace/>
                                    <ModalProductSummary/>
                                    <ModalGetAQuote/>

                                </ModalContext.Provider>
                            </RefContext.Provider>
                        </MeasurementContext.Provider>
                    </MenuContext.Provider>
                </EstimatedCostContext.Provider>
            </PanelAnimationContext.Provider>
        </SeeItInYourSpaceContext.Provider>
    );
}