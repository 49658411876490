import PropTypes from "prop-types";

DropdownMenu.propTypes = {
    title: PropTypes.string.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
    setMenuOpen: PropTypes.func.isRequired
};

export default function DropdownMenu(props) {
    const {
        title,
        contentHeight,
        isMenuOpen,
        setMenuOpen,
        actionButton
    } = props

    return (
        <>
            <div className={''}>
                <div className={'w-full flex'}>
                    <button
                        className={
                            'text-sm uppercase font-bold mt-2 pl-2 cursor-pointer border-l-4 grow text-left ' +
                            (isMenuOpen ? 'border-slidingdoorco-500' : 'hover:border-slidingdoorco-500')
                        }
                        onClick={setMenuOpen}
                    >
                        {title}
                    </button>
                    <button className={'pt-2 px-1'}>
                        {actionButton}
                    </button>
                </div>

                <div className={
                    'ml-3 overflow-hidden duration-200 transition-all '+
                    (isMenuOpen ? 'h-['+contentHeight+']': 'h-0')
                }
                     style={{height: isMenuOpen ? contentHeight : '0'}}
                >
                    {props.children}
                </div>
            </div>
        </>
    );
}