export default class DoorHandlingModel {
    constructor(
        id: string,
        name: string,
        icon: string,
    ) {
        this.id = id;
        this.name = name;
        this.icon = icon;
    }
}