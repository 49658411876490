import {useContext, useState, useEffect, useRef} from "react";
import ReactModal from "react-modal";
import {EstimatedCostContext, ModalContext, PanelAnimationContext, RefContext, SeeItInYourSpaceContext} from "../index";
import domToImage from 'dom-to-image';
import {DoorContext} from "../../../../controllers/DoorController";
import './ModalGetAQuote.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function ModalGetAQuote() {
    const {doorParameters} = useContext(DoorContext);
    const {doorPreviewRef} = useContext(RefContext);
    const {estimatedCost} = useContext(EstimatedCostContext);
    const {seeItInYourSpaceGeneratedImage} = useContext(SeeItInYourSpaceContext)
    const {isGetAQuoteModalOpen, setIsGetAQuoteModalOpen} = useContext(ModalContext);

    const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const txtFullNameRef = useRef(null);
    const txtPhoneRef = useRef(null);
    const txtEmailRef = useRef(null);
    const txtCityRef = useRef(null);
    const txtCommentRef = useRef(null);
    const [isJoinEmailChecked, setIsJoinEmailChecked] = useState(true);

    const onFormSubmit = () => {
        setLoadingMessage('Sending request');

        class  UploadDataClass {
            constructor() {
                this.full_name = txtFullNameRef.current.value;
                this.city = txtCityRef.current.value;
                this.phone = txtPhoneRef.current.value;
                this.email = txtEmailRef.current.value;
                this.comment = txtCommentRef.current.value;
                this.door_type = doorParameters.name;
                this.dimension_width =doorParameters.dimension.width;
                this.dimension_height = doorParameters.dimension.height;
                this.panel_count = doorParameters.getActivePanelConfigOption().panels.length;
                this.frame_finish = doorParameters.getActiveFrameFinishOption().name;
                this.divider_design = doorParameters.getActiveDividerDesignOption().name;
                this.glass_opacity = doorParameters.getActiveGlassOpacityOption().name;
                this.door_handling = doorParameters.getActiveDoorHandlingOption();
                this.swing_direction = doorParameters.getActiveSwingDirectionOption();
                this.estimated_cost = estimatedCost.replaceAll(',', '');
                this.door_preview = '';
                this.see_it_in_your_space_preview = '';

                this.door_handling = this.door_handling==null?'None':this.door_handling.name;
                this.swing_direction = this.swing_direction==null?'None':this.swing_direction.name;

                this.is_set_door_preview = false;
                this.is_set_see_it_in_your_space_preview = false;
            }

            setDoorPreview(door_preview){
                this.door_preview = door_preview;
                this.is_set_door_preview = true;

                if(this.is_set_door_preview && this.is_set_see_it_in_your_space_preview){
                    this.startUploadingData();
                }
            }

            setSeeItInYourSpacePreview(see_it_in_your_space_preview){
                this.see_it_in_your_space_preview = see_it_in_your_space_preview;
                this.is_set_see_it_in_your_space_preview = true;

                if(this.is_set_door_preview && this.is_set_see_it_in_your_space_preview){
                    this.startUploadingData();
                }
            }

            takeScreenShootDoorPreview(){
                const scale = 5;
                const self = this;
                domToImage.toPng(
                    doorPreviewRef.current,
                    {
                        width: doorPreviewRef.current.clientWidth * scale,
                        height: doorPreviewRef.current.clientHeight * scale,
                        style: {
                            transform: 'scale(' + scale + ')',
                            transformOrigin: 'top left'
                        }
                    }
                )
                    .then(function (dataUrl) {
                        self.setDoorPreview(dataUrl)
                    })
                ;
            }

            takeScreenShootSeeItInYourSpacePreview(){
                this.setSeeItInYourSpacePreview(seeItInYourSpaceGeneratedImage);
            }

            startUploadingData(){
                axios.post('/.netlify/functions/submit_get_a_quote', {
                    full_name: this.full_name,
                    city: this.city,
                    phone: this.phone,
                    email: this.email,
                    comment: this.comment,
                    door_type: this.door_type,
                    dimension_width: this.dimension_width,
                    dimension_height: this.dimension_height,
                    panel_count: this.panel_count,
                    frame_finish: this.frame_finish,
                    divider_design: this.divider_design,
                    glass_opacity: this.glass_opacity,
                    door_handling: this.door_handling,
                    swing_direction: this.swing_direction,
                    estimated_cost: this.estimated_cost,
                    optInToNewsletter: isJoinEmailChecked,
                    door_preview: this.door_preview,
                    see_it_in_your_space_preview: this.see_it_in_your_space_preview,
                })
                    .then(function (response) {
                        setLoadingMessage('');
                        setIsThankYouModalOpen(true);
                    })
                    .catch(function (error) {
                        console.log(error);
                        setLoadingMessage('');
                    });
            }
        }

        const uploadData = new UploadDataClass();
        uploadData.takeScreenShootDoorPreview();
        uploadData.takeScreenShootSeeItInYourSpacePreview();

    }

    return (
        <>
            <div
                className={
                    isGetAQuoteModalOpen ?
                        'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10 ' :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div className={'flex flex-col gap-2 bg-white p-2 my-auto mx-auto'} style={{maxWidth: '450px'}}>
                    <div className={'flex flex-col gap-2'}>
                        <div className={'text-sm'}>
                            <b>Let’s quote your project!</b>
                            <div className={'text-xs'}>
                                To save your design and request a quote,
                                please complete the form below. We will contact you within 48 hours.
                            </div>
                        </div>

                        <div className={'flex flex-row flex-wrap'}>
                            <div className="w-7/12 pr-2">
                                <label className="label">
                                    <span className="label-text">Full Name <span
                                        className={'text-red-500'}>*</span></span>
                                </label>
                                <input ref={txtFullNameRef} type="text" required={true}
                                       className="input input-bordered w-full rounded-none p-2 h-10"/>
                            </div>
                            <div className="w-5/12 pl-2">
                                <label className="label">
                                    <span className="label-text">City <span
                                        className={'text-red-500'}>*</span></span>
                                </label>
                                <input ref={txtCityRef} type="text" required={true}
                                       className="input input-bordered w-full rounded-none p-2 h-10"/>
                            </div>
                            <div className="w-7/12 pr-2">
                                <label className="label">
                                    <span className="label-text">Email <span className={'text-red-500'}>*</span></span>
                                </label>
                                <input ref={txtEmailRef} type="email" required={true}
                                       className="input input-bordered w-full rounded-none p-2 h-10"/>
                            </div>
                            <div className="w-5/12 pl-2">
                                <label className="label">
                                    <span className="label-text">Phone <span className={'text-red-500'}>*</span></span>
                                </label>
                                <input ref={txtPhoneRef} type="text" required={true}
                                       className="input input-bordered w-full rounded-none p-2 h-10"/>
                            </div>
                            <div className="w-full">
                                <label className="label">
                                    <span className="label-text">Comments</span>
                                </label>
                                <input ref={txtCommentRef} type="text"
                                       className="input input-bordered w-full rounded-none p-2 h-10"/>
                            </div>
                        </div>

                        <div className={'flex mt-2'}>
                            <button
                                className={'flex gap-2'}
                                onClick={() => {
                                    setIsJoinEmailChecked(!isJoinEmailChecked)
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={isJoinEmailChecked}
                                    className="checkbox rounded-none"
                                    onChange={() => {
                                    }}
                                />
                                <div className={'text-xs'}>
                                    Yes, I would like to receive marketing emails from The Sliding Door Company.
                                    <a className={'underline ml-1'}>Privacy Policy</a>
                                </div>
                            </button>
                        </div>

                        <div className={'flex mt-2'}>
                            <div className={'grow flex'}>
                                <button
                                    className={
                                        'btn rounded-none ' +
                                        'bg-slidingdoorco-header-100 border-slidingdoorco-header-100 ' +
                                        'hover:bg-slidingdoorco-header-500 hover:border-slidingdoorco-header-500'
                                    }
                                    onClick={() => {
                                        setIsGetAQuoteModalOpen(false)
                                    }}
                                >Close
                                </button>
                            </div>
                            <div className={'grow flex justify-end'}>
                                <button
                                    className={
                                        'btn rounded-none bg-slidingdoorco-500 border-slidingdoorco-500 p-0 ' +
                                        'hover:bg-slidingdoorco-500 hover:border-slidingdoorco-500 ' +
                                        // 'underline decoration-2 decoration-slidingdoorco-header-500 underline-offset-2 '
                                        ''
                                    }
                                    onClick={() => {
                                        let inputs = [
                                            txtFullNameRef.current,
                                            txtCityRef.current,
                                            txtPhoneRef.current,
                                            txtEmailRef.current,
                                            txtCommentRef.current
                                        ];

                                        let isAllFormValid = true;

                                        for (let input of inputs) {
                                            if (!input.checkValidity()) {
                                                isAllFormValid = false;
                                                input.reportValidity();
                                                break;
                                            }
                                        }

                                        if (!isAllFormValid) return;

                                        onFormSubmit();
                                    }}>
                                    <div
                                        id={'btnSendRequestLabel'}
                                        className={'mx-2 py-1'}
                                    >
                                        Send Request
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReactModal
                isOpen={isThankYouModalOpen}
                className={'modal-box mx-auto bottom-10 fixed sm:bottom-auto sm:static rounded-none'}
                appElement={document.getElementById('root')}
            >
                <div className={'flex flex-col gap-5'}>
                    <div className={'text-2xl text-center font-bold'}>
                        Thank you!
                    </div>
                    <div className={'text-center'}>
                        A sales representative will be in touch with you within 48 hours. In the meantime, check out
                        our <a className={'link text-blue-500'} href={'https://www.instagram.com/slidingdoorco/'} target={'_blank'}>Instagram</a> or learn more at our
                        website <a className={'link text-blue-500'} href={'https://www.slidingdoorco.com/'} target={'_blank'}>slidingdoorco.com</a>.
                    </div>
                </div>

                <div className="modal-action">
                    <button className="btn rounded-none" onClick={() => {
                        setIsGetAQuoteModalOpen(false);
                        setIsThankYouModalOpen(false);
                    }}>Close
                    </button>
                </div>
            </ReactModal>

            <div
                className={
                    loadingMessage !== '' ?
                        'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10 ' :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div className={'flex flex-row gap-2 bg-white p-2 my-auto mx-auto'} style={{maxWidth: '450px'}}>
                    <div className={''}>{loadingMessage}</div>
                    <div className={''}><FontAwesomeIcon icon={faSpinner} spin={true}/></div>
                </div>
            </div>
        </>
    );
}