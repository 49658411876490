export default class DimensionModel {
    constructor(
        width: number,
        height: number,
        minWidth: number,
        maxWidth: number,
        minHeight: number,
        maxHeight: number
    ) {
        this.width = width;
        this.height = height;
        this.defaultWidth = width;
        this.defaultHeight = height;
        this.minWidth = minWidth;
        this.maxWidth = maxWidth;
        this.minHeight = minHeight;
        this.maxHeight = maxHeight;
    }
}