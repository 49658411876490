import React, { useState } from 'react';
import {useRoutes} from 'react-router-dom';

import {DoorTypeSelector, CustomizeDoor} from 'controllers/DoorController'
import BasicLayout from "views/layout/BasicLayout";

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
    const [pageTitle, setPageTitle] = useState('OWN');

    return useRoutes([
        {
            path: '/',
            element: <BasicLayout pageTitle={pageTitle}/>,
            children: [
                {
                    path: '/',
                    element: <DoorTypeSelector setPageTitle={setPageTitle}/>
                }
            ]
        },
        {
            path: '/customize/:door_type',
            element: <BasicLayout pageTitle={pageTitle}/>,
            children: [
                {
                    path: '/customize/:door_type',
                    element: <CustomizeDoor pageTitle={pageTitle} setPageTitle={setPageTitle}/>
                }
            ]
        },
    ]);
}
