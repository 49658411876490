import React, {useContext, useEffect, useState, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {DoorContext} from "controllers/DoorController";
import {DropdownMenuStructure, MenuContext} from "../index";
import DropdownMenu from "./DropdownMenu";

export default function DropdownMenuContentFrameFinish() {
    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isMenuOpen, setMenuOpen} = useContext(MenuContext);

    const [isNextButtonVisible, setIsNextButtonVisible] = useState(true);
    const [isPrevButtonVisible, setIsPrevButtonVisible] = useState(true);

    const [frameFinishesOnDisplay, setFrameFinishesOnDisplay] = useState([]);

    const generateFrameFinishOnDisplay = useMemo(() => {
        return (targetId, indexIncrement = 0) => {
            let frameFinishes = [];

            let index = 0;
            let targetFound = false;

            while (frameFinishes.length !== 3) {
                let currentIndex = index % doorParameters.frameFinishes.length;

                if (doorParameters.frameFinishes[currentIndex].id === targetId && !targetFound) {
                    targetFound = true;
                    index += indexIncrement;

                    if (index < 0) {
                        index = doorParameters.frameFinishes.length - 1;
                    }

                    if(indexIncrement===0){
                        if(currentIndex<3) index=0;
                        else index -= 2;
                    }

                    currentIndex = index % doorParameters.frameFinishes.length;
                }

                if (targetFound) {
                    frameFinishes.push(doorParameters.frameFinishes[currentIndex]);
                }

                index++;
            }

            setFrameFinishesOnDisplay(frameFinishes);
        }
    }, [])

    useEffect(() => {
        generateFrameFinishOnDisplay(doorParameters.getActiveFrameFinishOption().id);
    }, []);

    useEffect(() => {
        if (frameFinishesOnDisplay.length === 0) return;

        setIsNextButtonVisible(frameFinishesOnDisplay.slice(-1)[0].id !== doorParameters.frameFinishes.slice(-1)[0].id);
        setIsPrevButtonVisible(frameFinishesOnDisplay[0].id !== doorParameters.frameFinishes[0].id);
    }, [frameFinishesOnDisplay]);

    return (
        <>
            <DropdownMenu
                title={'frame Finish'}
                contentHeight={'128px'}
                isMenuOpen={isMenuOpen.frameFinish}
                setMenuOpen={() => {
                    let dropdownMenuStructure = new DropdownMenuStructure();
                    dropdownMenuStructure.frameFinish = !isMenuOpen.frameFinish;
                    setMenuOpen(dropdownMenuStructure);
                }}
            >
                <div className={'h-32 bg-slidingdoorco-menu-content-500 flex'}>
                    <div className={'w-[32px] flex justify-center px-2'}>
                        <button
                            className={'flex flex-col justify-center z-10 ' + (isPrevButtonVisible ? '' : 'hidden')}
                            onClick={() => {
                                generateFrameFinishOnDisplay(frameFinishesOnDisplay[0].id, -1);
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleLeft}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>

                    <div className={'flex w-full overflow-hidden gap-2 py-2'}>
                        {
                            frameFinishesOnDisplay.map((frameFinish) => {
                                return (
                                    <React.Fragment key={frameFinish.id}>
                                        <button
                                            className={'flex flex-col justify-start w-1/3'}
                                            onClick={() => {
                                                let clone = doorParameters.createClone();
                                                clone.setActiveFrameFinishOption(frameFinish.id);
                                                setDoorParameters(clone);
                                            }}
                                        >
                                            <img
                                                src={frameFinish.icon}
                                                className={'mx-auto rounded-sm min-h-[90px] max-h-[90px]'}
                                                alt={frameFinish.id}
                                            />
                                            <div
                                                className={
                                                    'text-center text-white text-sm w-full ' +
                                                    (
                                                        doorParameters.getActiveFrameFinishOption().id === frameFinish.id ?
                                                            'font-bold' : ''
                                                    )
                                                }
                                            >
                                                {frameFinish.name}
                                            </div>
                                        </button>
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>

                    <div className={'w-[32px] flex justify-center px-2'}>
                        <button
                            className={'flex flex-col justify-center z-10 ' + (isNextButtonVisible ? '' : 'hidden')}
                            onClick={() => {
                                generateFrameFinishOnDisplay(frameFinishesOnDisplay[0].id, 1);
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleRight}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>

                </div>
            </DropdownMenu>
        </>
    );
}