import {Link, Outlet} from 'react-router-dom'
import Logo from "assets/logo_white.png";

export default function BasicLayout(props) {
    return (
        <>
            <div className={'flex flex-col h-screen'}>
                <nav className="flex  flex-col sm:flex-row bg-slidingdoorco-header-500 p-3 sm:p-6 gap-2">
                    <div className={'flex flex-grow justify-start items-center'}>
                        <Link to={'/'}>
                            <img src={Logo} className={'h-12 sm:h-20'} alt={'Logo icon'}/>
                        </Link>
                    </div>
                    <div
                        className={'flex flex-grow flex-row sm:flex-col md:flex-row justify-center gap-2 sm:justify-end items-center text-center text-slidingdoorco-500 text-lg sm:text-3xl font-bold'}>
                        DESIGN YOUR {props.pageTitle}
                    </div>
                </nav>
                <div className={'bg-slidingdoorco-500 h-1 w-screen xl:hidden'}/>

                <Outlet/>

                <div className={'bg-slidingdoorco-500 h-3 w-screen mt-auto hidden xl:block'}/>
            </div>
        </>
    );
}