import {useContext, useState, useEffect} from "react";
import {EstimatedCostContext, ModalContext, RefContext} from "../index";
import domToImage from 'dom-to-image';
import {DoorContext} from "../../../../controllers/DoorController";
import './ModalGetAQuote.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function ModalProductSummary() {
    const {doorParameters} = useContext(DoorContext);
    const {doorPreviewRef} = useContext(RefContext);
    const {estimatedCost} = useContext(EstimatedCostContext);
    const {
        setIsGetAQuoteModalOpen,
        isProductSummaryModalOpen, setIsProductSummaryModalOpen
    } = useContext(ModalContext);

    const [image, setImage] = useState('');

    const [loadingMessage, setLoadingMessage] = useState('');

    useEffect(async () => {
        if (isProductSummaryModalOpen) {
            setLoadingMessage('Loading door preview');
            domToImage.toPng(doorPreviewRef.current)
                .then(function (dataUrl) {
                    setImage(dataUrl);
                    setLoadingMessage('');
                })
            ;
        }
    }, [isProductSummaryModalOpen]);

    return (
        <>
            <div
                className={
                    isProductSummaryModalOpen ?
                        'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10  overflow-auto ' :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div className={'flex flex-col bg-white  my-auto mx-auto'} style={{maxWidth: '450px'}}>
                    <div className={'bg-slidingdoorco-header-500 text-sm p-4 text-slidingdoorco-500 font-bold text-center'}>
                        WHY CHOOSE THE SLIDING DOOR COMPANY:
                    </div>

                    <div
                        style={{
                            background: 'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(/images/intro/swingDoors.png )',
                            backgroundPosition: '50% 25%'
                        }}
                    >
                        <div
                            className={'text-xs font-bold px-2 py-6 text-white'}
                        >
                            <ul className={'list-disc ml-5'}>
                                <li>10-Year Warranty on all products.</li>
                                <li>266,642+ happy customers worldwide, and counting.</li>
                                <li>We are the source. From our factory, directly to you.</li>
                                <li>Customized to your needs. No two orders are the same.</li>
                                <li>Eco-friendly, sustainable materials.</li>
                                <li>Safety first. Quality always.</li>
                                <li>Successfully serving the community for 17 years.</li>
                            </ul>
                        </div>
                    </div>


                    <div className={'flex flex-col gap-2 p-2'}>
                        <div className={'text-sm'}>
                            <b>Product Summary</b>
                        </div>

                        <div className={'flex flex-col '}>
                            <img className={'max-h-[175px] mx-auto'} src={image} alt={'Screenshot'}/>

                            <table className={'mx-auto mt-2 text-sm'}>
                                <tbody>
                                <tr className={''}>
                                    <td className={'font-bold text-right pr-2'}>Dimension:</td>
                                    <td>{
                                        doorParameters.dimension.width + ' x ' +
                                        doorParameters.dimension.height + ' inches '
                                    }</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'font-bold text-right pr-2'}>Panel Count:</td>
                                    <td>{doorParameters.getActivePanelConfigOption().panels.length}</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'font-bold text-right pr-2'}>Frame Finish:</td>
                                    <td>{doorParameters.getActiveFrameFinishOption().name}</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'font-bold text-right pr-2'}>Divider Design:</td>
                                    <td>{doorParameters.getActiveDividerDesignOption().name}</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'font-bold text-right pr-2'}>Glass Opacity:</td>
                                    <td className={''}>{doorParameters.getActiveGlassOpacityOption().name}</td>
                                </tr>
                                {
                                    doorParameters.id === 'swing_doors' && doorParameters.getActivePanelConfigOption().panels.length === 1 ?
                                        <>
                                            <tr className={''}>
                                                <td className={'font-bold text-right pr-2'}>Door Handling:</td>
                                                <td className={''}>{doorParameters.getActiveDoorHandlingOption().name}</td>
                                            </tr>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    doorParameters.id === 'swing_doors' ?
                                        <>
                                            <tr className={''}>
                                                <td className={'font-bold text-right pr-2'}>Swing Direction:</td>
                                                <td className={''}>{doorParameters.getActiveSwingDirectionOption().name}</td>
                                            </tr>
                                        </>
                                        :
                                        <></>
                                }
                                <tr className={''}>
                                    <td className={'font-bold text-right pr-2'}>Estimated Cost:</td>
                                    <td className={'flex gap-2'}>
                                        <span>${estimatedCost}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={'text-xs text-center'}>
                                        <br/>
                                        *This is an estimate based on your current selections. Prices may increase or
                                        decrease depending on additional customization options beyond this tool. Volume
                                        discounts are available.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={'flex mt-2'}>
                            <div className={'grow flex'}>
                                <button
                                    className={
                                        'btn rounded-none ' +
                                        'bg-slidingdoorco-header-100 border-slidingdoorco-header-100 ' +
                                        'hover:bg-slidingdoorco-header-500 hover:border-slidingdoorco-header-500'
                                    }
                                    onClick={() => {
                                        setIsProductSummaryModalOpen(false)
                                    }}
                                >
                                    Keep Editing
                                </button>
                            </div>
                            <div className={'grow flex justify-end'}>
                                <button
                                    className={
                                        'btn rounded-none bg-slidingdoorco-500 border-slidingdoorco-500 p-0 ' +
                                        'hover:bg-slidingdoorco-500 hover:border-slidingdoorco-500 ' +
                                        // 'underline decoration-2 decoration-slidingdoorco-header-500 underline-offset-2 '
                                        ''
                                    }
                                    onClick={() => {
                                        setIsProductSummaryModalOpen(false);
                                        setIsGetAQuoteModalOpen(true);
                                    }}>
                                    <div
                                        id={'btnSendRequestLabel'}
                                        className={'mx-2 py-1'}
                                    >
                                        finalize your price
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={
                    loadingMessage !== '' ?
                        'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10 ' :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div className={'flex flex-row gap-2 bg-white p-2 my-auto mx-auto'} style={{maxWidth: '450px'}}>
                    <div className={''}>{loadingMessage}</div>
                    <div className={''}><FontAwesomeIcon icon={faSpinner} spin={true}/></div>
                </div>
            </div>
        </>
    );
}