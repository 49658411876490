import React, {useContext, useRef, useState, useEffect, useCallback, useMemo} from "react"
import {ModalContext, RefContext, SeeItInYourSpaceContext} from "../index";
import './ModalSeeItInYourSpace.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faArrowRightArrowLeft,
    faClose,
    faDownload,
    faEnvelope, faRepeat,
    faUpload,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {drag, select, selectAll} from "d3";
import {DoorContext} from "../../../../controllers/DoorController";
import domToImage from "dom-to-image";
import axios from "axios";
import ReactModal from "react-modal";

export default function ModalSeeItInYourSpace() {
    const {doorParameters} = useContext(DoorContext);
    const {doorPreviewRef} = useContext(RefContext);
    const {setSeeItInYourSpaceGeneratedImage} = useContext(SeeItInYourSpaceContext);
    const {
        isSeeItInYourSpaceModalOpen,
        setIsSeeItInYourSpaceModalOpen,
        setIsProductSummaryModalOpen,
    } = useContext(ModalContext);

    const [backgroundImage, setBackgroundImage] = useState('');
    const [doorImage, setDoorImage] = useState('');
    const [isPreparingScreenshot, setIsPreparingScreenshot] = useState(false);
    const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
    const [isEmailSuccessModalOpen, setIsEmailSuccessModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [seeItInYourSpaceDimension, setSeeItInYourSpaceDimension] = useState(null);

    const txtEmailRef = useRef(null);
    const containerSeeItInYourSpaceModal = useRef(null);

    const perspectiveTransformContainerRef = useRef(null);
    const mobileBottomActionContainerRef = useRef(null);

    const [mainContentHeight, setMainContentHeight] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const [mobileBottomActionContainerHeight, setMobileBottomActionContainerHeight] = useState(0);

    useEffect(async () => {
        if (isSeeItInYourSpaceModalOpen) {
            const scale = 5;

            domToImage.toPng(
                doorPreviewRef.current,
                {
                    width: doorPreviewRef.current.clientWidth * scale,
                    height: doorPreviewRef.current.clientHeight * scale,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left'
                    }
                }
            )
                .then(function (dataUrl) {
                    setDoorImage(dataUrl);
                })
            ;
        }
    }, [isSeeItInYourSpaceModalOpen]);

    useEffect(() => {
        if (document.getElementById("perspectiveTransformSurface") !== null) {
            document.getElementById("perspectiveTransformSurface").style.visibility = isPreparingScreenshot ? 'hidden' : 'visible';
        }

        if (document.getElementById("perspectiveTransformImage") !== null && !isSafari) {
            document.getElementById("perspectiveTransformImage").style.left = isPreparingScreenshot ? 0 : '';
            document.getElementById("perspectiveTransformImage").style.top = isPreparingScreenshot ? 0 : '';
        }
    }, [isPreparingScreenshot]);

    useEffect(() => {
        setIsDragging(false);

        setMobileBottomActionContainerHeight(
            mobileBottomActionContainerRef.current ?
                mobileBottomActionContainerRef
                    .current
                    .parentElement
                    .parentElement
                    .parentElement
                    .getBoundingClientRect()
                    .height :
                0
        );

        const handleResize = () => {
            if (perspectiveTransformContainerRef.current !== null) {
                setMainContentHeight(perspectiveTransformContainerRef.current.parentElement.parentElement.getBoundingClientRect().height);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return _ => {
            window.removeEventListener('resize', handleResize)
        }

    }, [backgroundImage]);

    const onClickBtnDownload = async () => {
        setIsPreparingScreenshot(true);
        setLoadingMessage('Preparing for download');

        while (document.getElementById("perspectiveTransformSurface").style.visibility !== 'hidden') {
            await new Promise(r => setTimeout(r, 500));
        }

        if (isSafari) {
            setIsPreviewModalOpen(true);
            setLoadingMessage('');
            return;
        }

        const scale = 3;
        domToImage.toJpeg(
            perspectiveTransformContainerRef.current,
            {
                width: perspectiveTransformContainerRef.current.clientWidth * scale,
                height: perspectiveTransformContainerRef.current.clientHeight * scale,
                style: {
                    transform: 'scale(' + scale + ')',
                    transformOrigin: 'top left',
                    // backgroundImage: 'url(\''+perspectiveTransformContainerRef.current.children[1].src+'\')'
                }
            }
        ).then(function (dataUrl) {
            const toTitleCase = (str) => {
                return str.replace(
                    /\w\S*/g,
                    function (txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    }
                );
            }
            let filename = 'The Sliding Door Co – Design Your ' + toTitleCase(doorParameters.name) + ' ' + (new Date().toLocaleString("en-US")) + '.jpg';

            setLoadingMessage('');
            setIsPreparingScreenshot(false);
            setSeeItInYourSpaceGeneratedImage(dataUrl);

            var link = document.createElement('a');
            link.download = filename;
            link.href = dataUrl;
            link.click();
        }).catch((error) => {
            console.log(error);
        });
    }

    const onClickBtnEmail = async () => {
        setIsPreparingScreenshot(true);
        setLoadingMessage('Sending email');

        while (document.getElementById("perspectiveTransformSurface").style.visibility !== 'hidden') {
            await new Promise(r => setTimeout(r, 500));
        }

        const scale = 3;
        domToImage.toJpeg(
            perspectiveTransformContainerRef.current,
            {
                width: perspectiveTransformContainerRef.current.clientWidth * scale,
                height: perspectiveTransformContainerRef.current.clientHeight * scale,
                style: {
                    transform: 'scale(' + scale + ')',
                    transformOrigin: 'top left'
                }
            }
        ).then(function (dataUrl) {
            setIsPreparingScreenshot(false);

            setSeeItInYourSpaceGeneratedImage(dataUrl);

            const door_type = doorParameters.name;
            const dimension_width = doorParameters.dimension.width;
            const dimension_height = doorParameters.dimension.height;
            const panel_count = doorParameters.getActivePanelConfigOption().panels.length;
            const frame_finish = doorParameters.getActiveFrameFinishOption().name;
            const divider_design = doorParameters.getActiveDividerDesignOption().name;
            const glass_opacity = doorParameters.getActiveGlassOpacityOption().name;
            let door_handling = doorParameters.getActiveDoorHandlingOption();
            let swing_direction = doorParameters.getActiveSwingDirectionOption();

            door_handling = door_handling == null ? 'None' : door_handling.name;
            swing_direction = swing_direction == null ? 'None' : swing_direction.name;

            axios.post('/.netlify/functions/send_see_it_in_your_space', {
                email: txtEmailRef.current.value,
                image: dataUrl,
                door_type: door_type,
                dimension_width: dimension_width,
                dimension_height: dimension_height,
                panel_count: panel_count,
                frame_finish: frame_finish,
                divider_design: divider_design,
                glass_opacity: glass_opacity,
                door_handling: door_handling,
                swing_direction: swing_direction
            })
                .then(function (response) {
                    setLoadingMessage('');
                    setIsEmailSuccessModalOpen(true);
                })
                .catch(function (error) {
                    console.log(error);
                    setLoadingMessage('');
                });
        });
    }

    const onClickGetAQuote = async () => {
        setIsPreparingScreenshot(true);
        setIsProductSummaryModalOpen(true);

        while (document.getElementById("perspectiveTransformSurface").style.visibility !== 'hidden') {
            await new Promise(r => setTimeout(r, 500));
        }

        const scale = 3;
        domToImage.toJpeg(
            perspectiveTransformContainerRef.current,
            {
                width: perspectiveTransformContainerRef.current.clientWidth * scale,
                height: perspectiveTransformContainerRef.current.clientHeight * scale,
                style: {
                    transform: 'scale(' + scale + ')',
                    transformOrigin: 'top left'
                }
            }
        ).then(function (dataUrl) {
            setIsPreparingScreenshot(false);
            setSeeItInYourSpaceGeneratedImage(dataUrl);
        });
    }

    const onLoadBackgroundImage = useCallback((event) => {
        let browserTransform = ["", "-webkit-", "-moz-", "-ms-", "-o-"].reduce(function (p, v) {
            return v + "transform" in document.body.style ? v : p;
        }) + "transform";
        let margin = {
            top: event.target.height / 2 - doorPreviewRef.current.offsetHeight / 2,
            right: event.target.width / 2 - doorPreviewRef.current.offsetWidth / 2,
            bottom: event.target.height / 2 - doorPreviewRef.current.offsetHeight / 2,
            left: event.target.width / 2 - doorPreviewRef.current.offsetWidth / 2
        };
        let width = event.target.width - margin.left - margin.right;
        let height = event.target.height - margin.top - margin.bottom;

        let perspectiveTransformImage = select("#perspectiveTransformImage");
        let perspectiveTransformSurface = select("#perspectiveTransformSurface");

        perspectiveTransformImage
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .select('g')
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        ;

        perspectiveTransformSurface
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .select('g')
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        ;

        perspectiveTransformImage
            .style(browserTransform + "-origin", margin.left + "px " + margin.top + "px 0")
        // .style('margin', '')
        ;

        perspectiveTransformImage
            .select('g')
            .append("image")
            .attr("href", doorImage)
            .attr("width", width)
            .attr("height", height)
        ;

        let sourcePoints = [[0, 0], [width, 0], [width, height], [0, height]];
        let targetPoints = [[0, 0], [width, 0], [width, height], [0, height]];
        let activePerspectiveTransformHandle;

        const solve = (paramA, paramB, paramFast) => {
            function _foreach2(x, s, k, f) {
                if (k === s.length - 1) return f(x);
                let i;
                let n = s[k], ret = Array(n);
                for (i = n - 1; i >= 0; --i) ret[i] = _foreach2(x[i], s, k + 1, f);
                return ret;
            }

            function _dim(x) {
                let ret = [];
                while (typeof x === "object") {
                    ret.push(x.length);
                    x = x[0];
                }
                return ret;
            }

            function dim(x) {
                let y;
                let z;
                if (typeof x === "object") {
                    y = x[0];
                    if (typeof y === "object") {
                        z = y[0];
                        if (typeof z === "object") {
                            return _dim(x);
                        }
                        return [x.length, y.length];
                    }
                    return [x.length];
                }
                return [];
            }

            function cloneV(x) {
                let _n = x.length;
                let i;
                let ret = Array(_n);
                for (i = _n - 1; i !== -1; --i) ret[i] = x[i];
                return ret;
            }

            function clone(x) {
                return typeof x !== "object" ? x : _foreach2(x, dim(x), 0, cloneV);
            }

            function LU(A, fast) {
                fast = fast || false;

                let i, j, k, absAjk, Akk, Ak, Pk, Ai,
                    max,
                    n = A.length, n1 = n - 1,
                    P = new Array(n);

                if (!fast) A = clone(A);

                for (k = 0; k < n; ++k) {
                    Pk = k;
                    Ak = A[k];
                    max = Math.abs(Ak[k]);
                    for (j = k + 1; j < n; ++j) {
                        absAjk = Math.abs(A[j][k]);
                        if (max < absAjk) {
                            max = absAjk;
                            Pk = j;
                        }
                    }
                    P[k] = Pk;

                    if (Pk !== k) {
                        A[k] = A[Pk];
                        A[Pk] = Ak;
                        Ak = A[k];
                    }

                    Akk = Ak[k];

                    for (i = k + 1; i < n; ++i) {
                        A[i][k] /= Akk;
                    }

                    for (i = k + 1; i < n; ++i) {
                        Ai = A[i];
                        for (j = k + 1; j < n1; ++j) {
                            Ai[j] -= Ai[k] * Ak[j];
                            ++j;
                            Ai[j] -= Ai[k] * Ak[j];
                        }
                        if (j === n1) Ai[j] -= Ai[k] * Ak[j];
                    }
                }

                return {
                    LU: A,
                    P: P
                };
            }

            function LUsolve(LUP, b) {
                let i, j,
                    LU = LUP.LU,
                    n = LU.length,
                    x = clone(b),
                    P = LUP.P,
                    Pi, LUi, tmp;

                for (i = n - 1; i !== -1; --i) x[i] = b[i];
                for (i = 0; i < n; ++i) {
                    Pi = P[i];
                    if (P[i] !== i) {
                        tmp = x[i];
                        x[i] = x[Pi];
                        x[Pi] = tmp;
                    }
                    LUi = LU[i];
                    for (j = 0; j < i; ++j) {
                        x[i] -= x[j] * LUi[j];
                    }
                }

                for (i = n - 1; i >= 0; --i) {
                    LUi = LU[i];
                    for (j = i + 1; j < n; ++j) {
                        x[i] -= x[j] * LUi[j];
                    }
                    x[i] /= LUi[i];
                }

                return x;
            }

            return LUsolve(LU(paramA, paramFast), paramB);
        }

        const dragged = (event, d) => {
            if (activePerspectiveTransformHandle.getAttribute("data-index") == null) {
                return;
            }

            select(activePerspectiveTransformHandle).attr("transform", "translate(" + (d[0] = event.x) + "," + (d[1] = event.y) + ")");
            transformed();
        }

        const transformed = () => {
            for (var a = [], b = [], i = 0, n = sourcePoints.length; i < n; ++i) {
                var s = sourcePoints[i], t = targetPoints[i];
                a.push([s[0], s[1], 1, 0, 0, 0, -s[0] * t[0], -s[1] * t[0]]);
                b.push(t[0]);
                a.push([0, 0, 0, s[0], s[1], 1, -s[0] * t[1], -s[1] * t[1]]);
                b.push(t[1]);
            }

            var X = solve(a, b, true), matrix = [
                X[0], X[3], 0, X[6],
                X[1], X[4], 0, X[7],
                0, 0, 1, 0,
                X[2], X[5], 0, 1
            ].map(function (x) {
                return x.toFixed(6);
            });

            perspectiveTransformImage.style(browserTransform, "matrix3d(" + matrix + ")");
        }

        perspectiveTransformSurface.select("g")
            .selectAll(".perspectiveTransformHandle")
            .data(targetPoints)
            .enter()
            .append("circle")
            .attr("class", "perspectiveTransformHandle")
            .attr("data-index", function (d, i) {
                return i;
            })
            .attr("transform", function (d, i) {
                return "translate(" + d + ")";
            })
            .attr("r", 7)

        ;

        selectAll(".perspectiveTransformHandle")
            .call(drag()
                .on('start', (event) => {
                    activePerspectiveTransformHandle = event.sourceEvent.srcElement
                })
                .on('drag', dragged)
            )
        ;

        setSeeItInYourSpaceDimension(containerSeeItInYourSpaceModal.current.getBoundingClientRect());
    }, [doorImage]);

    const isSafari = useMemo(() => {
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;
    }, []);

    return (
        <>

            <div

                className={
                    isSeeItInYourSpaceModalOpen ?
                        'absolute h-screen w-screen top-0 left-0 flex xl:flex-row xl:justify-start p-5 sm:p-10 '
                        :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div
                    className={'grow flex flex-col xl:flex-row gap-2 xl:gap-5'}
                >
                    <div className={'flex flex-col w-full grow overflow-hidden'}>
                        <div
                            className={'bg-black text-white flex flex-col-reverse justify-center text-center h-full'}

                        >
                            {
                                backgroundImage === '' ?
                                    <div
                                        className={
                                            'w-60 h-60 border border-dashed border-4 mx-auto flex flex-col justify-center '
                                            + (isDragging ? 'border-white' : 'border-gray-500')
                                        }
                                        onDragOver={() => {
                                            setIsDragging(true);
                                        }}
                                        onDragLeave={() => {
                                            setIsDragging(false);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faUpload} className={'h-16 w-full pb-2'}/>
                                        <div className={'flex justify-center w-full font-bold'}>Drag & Drop</div>
                                        <div className={'flex justify-center w-full'}>OR</div>
                                        <div className={'flex justify-center w-full font-bold'}>Browse Image</div>
                                        <input type="file" accept="image/*"
                                               className={'absolute w-60 h-60 opacity-0'}
                                               onChange={(event) => {
                                                   setBackgroundImage(URL.createObjectURL(event.target.files[0]));
                                               }}/>
                                    </div>
                                    :
                                    <>
                                        <div
                                            className={'flex w-full justify-center'}
                                        >
                                            <div
                                                className={''}
                                                ref={perspectiveTransformContainerRef}
                                                onClick={() => {
                                                    setIsPreparingScreenshot(false);
                                                }}
                                                style={{
                                                    height: seeItInYourSpaceDimension === null ? null : seeItInYourSpaceDimension.height + 'px',
                                                    width: seeItInYourSpaceDimension === null ? null : seeItInYourSpaceDimension.width + 'px',
                                                }}
                                            >
                                                <svg className={'fixed overflow-visible'}
                                                     id={'perspectiveTransformImage'}>
                                                    <g/>
                                                </svg>
                                                <img
                                                    alt={'Uploaded image'}
                                                    src={backgroundImage}
                                                    className={''}
                                                    onLoad={onLoadBackgroundImage}
                                                    style={{
                                                        maxHeight: seeItInYourSpaceDimension === null?mainContentHeight + 'px':null,
                                                        height: seeItInYourSpaceDimension === null ? null : seeItInYourSpaceDimension.height + 'px',
                                                        width: seeItInYourSpaceDimension === null ? null : seeItInYourSpaceDimension.width + 'px',
                                                    }}
                                                />
                                            </div>

                                            <svg
                                                className={'fixed overflow-visible'}
                                                id={'perspectiveTransformSurface'}
                                                ref={containerSeeItInYourSpaceModal}
                                                style={{
                                                    height: seeItInYourSpaceDimension === null ? null : seeItInYourSpaceDimension.height + 'px',
                                                    width: seeItInYourSpaceDimension === null ? null : seeItInYourSpaceDimension.width + 'px',
                                                }}
                                            >
                                                <g/>
                                            </svg>

                                        </div>
                                    </>
                            }

                        </div>
                        <div
                            className={'h-0 relative flex flex-col'}
                            style={{
                                bottom: '100%'
                            }}
                        >
                            <div className={'h-0 xl:hidden'}>
                                <div className={'relative'}>
                                    <div
                                        className={'flex w-full text-white p-2 ' + (isPreparingScreenshot ? 'hidden' : '')}>
                                        <div className={'grow font-medium text-lg hidden sm:flex'}>
                                            Visualize it in your Space
                                        </div>

                                        <div className={'grow flex justify-end gap-2'}>
                                            {
                                                backgroundImage === '' ?
                                                    <></>
                                                    :
                                                    <>
                                                        <button className={'flex flex-row gap-2'} onClick={() => {
                                                            setBackgroundImage('');
                                                            setSeeItInYourSpaceDimension(null);
                                                        }}>
                                                            <div className={'drop-shadow-lg'}>Start Over</div>
                                                            <div><FontAwesomeIcon icon={faRepeat}/></div>
                                                        </button>
                                                        <div className={'drop-shadow-lg'}>|</div>
                                                    </>
                                            }
                                            <button className={'flex flex-row gap-2'} onClick={() => {
                                                setIsSeeItInYourSpaceModalOpen(false);
                                            }}>
                                                <div className={'drop-shadow-lg'}>Close</div>
                                                <div><FontAwesomeIcon icon={faClose}/></div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'relative h-0 xl:hidden'}>
                                <div
                                    className={'relative flex justify-end'}
                                    style={{
                                        marginTop:
                                            backgroundImage !== '' && mobileBottomActionContainerRef.current ?
                                                'calc(' + mobileBottomActionContainerHeight + 'px - 44px - 24px)' : ''
                                    }}
                                    ref={mobileBottomActionContainerRef}
                                >
                                    <div className={'flex p-2 text-white ' + (isPreparingScreenshot ? 'hidden' : '')}>
                                        {
                                            backgroundImage === '' ?
                                                <></>
                                                :
                                                <>
                                                    <div className={'h-0'}>
                                                        <div className={'flex flex-col justify-end gap-2'}>
                                                            <button
                                                                className={'flex flex-row justify-end gap-2 ' + (isSafari ? 'w-0 overflow-hidden' : '')}
                                                                onClick={() => {
                                                                    setIsEmailDialogOpen(true);
                                                                }}>
                                                                <div
                                                                    className={'drop-shadow-lg flex'}>Email
                                                                </div>
                                                                <div><FontAwesomeIcon icon={faEnvelope}/></div>
                                                            </button>
                                                            <button className={'flex flex-row justify-end gap-2'}
                                                                    onClick={onClickBtnDownload}>
                                                                <div
                                                                    className={'drop-shadow-lg flex'}>Download
                                                                </div>
                                                                <div><FontAwesomeIcon icon={faDownload}/></div>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'hidden xl:flex'}>
                        <div
                            style={{height: 'calc(100vh - calc(5rem + 3rem + 1.25rem + 24px))'}}
                        >
                            <div className={'min-w-[300px] flex flex-col p-2 gap-4 bg-white rounded-none'}>
                                <div className={'flex justify-end gap-2'}>
                                    <button className={'flex flex-row gap-2'} onClick={() => {
                                        setIsSeeItInYourSpaceModalOpen(false);
                                    }}>
                                        <div className={' drop-shadow-lg'}>Close</div>
                                        <div><FontAwesomeIcon icon={faClose}/></div>
                                    </button>
                                </div>

                                <div className={'flex flex-col gap-2 mx-2'}>
                                    <button
                                        className={'flex flex-row gap-2 ' + (backgroundImage !== '' ? '' : 'opacity-50 ')}
                                        onClick={() => {
                                            setBackgroundImage('');
                                            setSeeItInYourSpaceDimension(null);
                                        }}
                                    >
                                        <div><FontAwesomeIcon icon={faArrowRightArrowLeft}/></div>
                                        <div className={'drop-shadow-lg'}>Reset</div>
                                    </button>
                                </div>

                                <div className={'pl-8'}>
                                    <div className={'w-full h-1 bg-black'}/>
                                </div>

                                <div className={'flex flex-col gap-2 mx-2'}>
                                    <button
                                        className={'flex flex-row gap-2 ' + (backgroundImage !== '' ? '' : 'opacity-50')}
                                        onClick={() => {
                                            if (backgroundImage !== '') {
                                                onClickBtnDownload();
                                            }
                                        }}
                                    >
                                        <div><FontAwesomeIcon icon={faDownload}/></div>
                                        <div className={'drop-shadow-lg'}>Download</div>
                                    </button>

                                    {
                                        !isSafari ?
                                            <>
                                                <button
                                                    className={'flex flex-row gap-2 ' + (backgroundImage !== '' ? '' : 'opacity-50 ')}
                                                    onClick={() => {
                                                        if (backgroundImage !== '') {
                                                            setIsEmailDialogOpen(true);
                                                        }
                                                    }}
                                                >
                                                    <div><FontAwesomeIcon icon={faEnvelope}/></div>
                                                    <div className={'drop-shadow-lg'}>Email</div>
                                                </button>
                                            </>
                                            :
                                            <></>
                                    }
                                </div>

                                <button
                                    className={'' +
                                        'btn w-full mt-8 ' +
                                        'border-t-0 border-b-0 border-r-0 border-l-8 border-slidingdoorco-500 rounded-none ' +
                                        'hover:border-t-0 hover:border-b-0 hover:border-r-0 hover:border-l-8 hover:border-slidingdoorco-500 ' +
                                        'bg-slidingdoorco-header-500 '
                                    }
                                    onClick={() => {
                                        onClickGetAQuote();
                                    }}
                                >
                                    SAVE & SEE ESTIMATE
                                    <FontAwesomeIcon className={'ml-2 text-slidingdoorco-500 font-bold'}
                                                     icon={faAnglesRight}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full xl:hidden ' + (backgroundImage === '' ? 'hidden' : '')}>
                        <button
                            className={'' +
                                'btn w-full ' +
                                'border-t-0 border-b-0 border-r-0 border-l-8 border-slidingdoorco-500 rounded-none ' +
                                'hover:border-t-0 hover:border-b-0 hover:border-r-0 hover:border-l-8 hover:border-slidingdoorco-500 ' +
                                'bg-slidingdoorco-header-500 '
                            }
                            onClick={() => {
                                setIsProductSummaryModalOpen(true);
                            }}
                        >
                            SAVE & SEE ESTIMATE
                            <FontAwesomeIcon className={'ml-2 text-slidingdoorco-500 font-bold'} icon={faAnglesRight}/>
                        </button>
                    </div>
                </div>
            </div>


            <div
                className={
                    loadingMessage !== '' ?
                        'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10 ' :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div className={'flex flex-row gap-2 bg-white p-2 my-auto mx-auto'} style={{maxWidth: '450px'}}>
                    <div className={''}>{loadingMessage}</div>
                    <div className={''}><FontAwesomeIcon icon={faSpinner} spin={true}/></div>
                </div>
            </div>

            <div
                className={
                    isEmailDialogOpen ?
                        'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10 ' :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div className={'flex flex-col gap-4 bg-white p-2 my-auto mx-auto'}
                     style={{width: '450px', maxWidth: '450px'}}>

                    <div className={'text-lg'}>
                        <b>Enter an email address to share your design:</b>
                    </div>

                    <div className={'flex flex-col gap-2'}>
                        <div className="w-full">
                            <input
                                type="email" required={true}
                                className="input input-bordered w-full rounded-none p-2 h-10"
                                ref={txtEmailRef}
                            />
                        </div>
                    </div>

                    <div className={'flex'}>
                        <div className={'grow flex'}>
                            <button
                                className={
                                    'btn rounded-none ' +
                                    'bg-slidingdoorco-header-100 border-slidingdoorco-header-100 ' +
                                    'hover:bg-slidingdoorco-header-500 hover:border-slidingdoorco-header-500'
                                }
                                onClick={() => {
                                    setIsEmailDialogOpen(false)
                                }}
                            >Close
                            </button>
                        </div>
                        <div className={'grow flex justify-end'}>
                            <button
                                className={
                                    'btn rounded-none bg-slidingdoorco-500 border-slidingdoorco-500 p-0 ' +
                                    'hover:bg-slidingdoorco-500 hover:border-slidingdoorco-500 '
                                }
                                onClick={() => {
                                    if (!txtEmailRef.current.checkValidity()) {
                                        txtEmailRef.current.reportValidity();
                                        return;
                                    }

                                    setIsEmailDialogOpen(false);
                                    onClickBtnEmail();
                                }}>
                                <div
                                    id={'btnSendRequestLabel'}
                                    className={'mx-2 py-1'}
                                >
                                    Send Email
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={
                    isPreviewModalOpen ?
                        'absolute w-screen h-screen top-0 left-0 flex xl:flex-row xl:justify-start p-2 sm:p-10 ' :
                        'hidden'
                }
                style={{
                    backgroundColor: 'rgba(0,0,0,.75)',
                    transition: 'visibility 0s linear 0s, opacity .2 0s'
                }}
            >
                <div className={'flex flex-col w-80 gap-3 bg-white p-2 my-auto mx-auto gap-2'}
                     style={{maxWidth: '450px'}}>
                    <div className={'flex justify-end'}>
                        <button className={'flex flex-row justify-center gap-2 hover:bg-gray-300 w-5 h-5'}
                                onClick={() => {
                                    setIsPreviewModalOpen(false);
                                }}>
                            <div><FontAwesomeIcon icon={faClose}/></div>
                        </button>
                    </div>
                    <div className={'flex justify-center mb-4 text-center'}>
                        Due to the security features of your browser, we are unable to download or email this photo.
                        <br/>
                        <br/>
                        Please capture a manual screenshot of your design.
                        <br/>
                        <br/>
                        Thank you.
                    </div>
                </div>
            </div>

            <ReactModal
                isOpen={isEmailSuccessModalOpen}
                className={'modal-box mx-auto bottom-10 fixed sm:bottom-auto sm:static rounded-none'}
                appElement={document.getElementById('root')}
            >
                <div className={'flex flex-col gap-5'}>
                    <div className={'text-2xl text-center font-bold'}>
                        Email Sent Successfully
                    </div>
                    <div className={'text-center'}>
                        Please check your email for the photo of your customized product in your space!<br/><br/>
                        Want more inspiration? Check out our <a className={'link text-blue-500'}
                                                                href={'https://www.instagram.com/slidingdoorco/'}
                                                                target={'_blank'}>Instagram</a> or learn more at our
                        website <a className={'link text-blue-500'} href={'https://www.slidingdoorco.com/'}
                                   target={'_blank'}>slidingdoorco.com</a>.
                    </div>
                </div>

                <div className="modal-action">
                    <button className="btn rounded-none" onClick={() => {
                        setIsEmailSuccessModalOpen(false);
                    }}>Close
                    </button>
                </div>
            </ReactModal>
        </>
    );
}