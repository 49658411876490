import {useContext, useRef, useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faSquareMinus, faSquarePlus} from "@fortawesome/free-solid-svg-icons";
import {DoorContext} from "../../../../controllers/DoorController";
import ReactModal from "react-modal";
import {DropdownMenuStructure, MenuContext} from "../index";
import DropdownMenu from "./DropdownMenu";

export default function DropdownMenuContentDimensions() {
    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isMenuOpen, setMenuOpen} = useContext(MenuContext);

    const [isDimensionHelpModalOpen, setDimensionHelpModalOpen] = useState(false);

    const refInputDimensionWidth = useRef(null);
    const refInputDimensionHeight = useRef(null);

    const addDimensionWidth = (increment) => {
        let currentValue = parseInt(refInputDimensionWidth.current.value);
        let newValue = currentValue + increment;

        if (increment > 0) {
            if (newValue <= doorParameters.dimension.maxWidth) {
                refInputDimensionWidth.current.value = newValue;
                eventInputDimensionWidthOnChange();
            }
        } else {
            if (newValue >= doorParameters.dimension.minWidth) {
                refInputDimensionWidth.current.value = newValue;
                eventInputDimensionWidthOnChange();
            }
        }
    }

    const addDimensionHeight = (increment) => {
        let currentValue = parseInt(refInputDimensionHeight.current.value);
        let newValue = currentValue + increment;

        if (increment > 0) {
            if (newValue <= doorParameters.dimension.maxHeight) {
                refInputDimensionHeight.current.value = newValue;
                eventInputDimensionHeightOnChange();
            }

        } else {
            if (newValue >= doorParameters.dimension.minHeight) {
                refInputDimensionHeight.current.value = newValue;
                eventInputDimensionHeightOnChange();
            }

        }
    }

    const eventInputDimensionWidthOnChange = () => {
        if (refInputDimensionWidth.current.checkValidity()) {
            let copyDoorParameters = doorParameters.createClone();
            copyDoorParameters.dimension.width =
                parseInt(refInputDimensionWidth.current.value)
            ;
            setDoorParameters(copyDoorParameters);
        }
    }

    const eventInputDimensionHeightOnChange = () => {
        if (refInputDimensionHeight.current.checkValidity()) {
            let copyDoorParameters = doorParameters.createClone();
            copyDoorParameters.dimension.height =
                parseInt(refInputDimensionHeight.current.value)
            ;
            setDoorParameters(copyDoorParameters);
        }
    }

    useEffect(() => {
        refInputDimensionWidth.current.value = doorParameters.dimension.width;
        refInputDimensionHeight.current.value = doorParameters.dimension.height;
    }, [doorParameters.dimension.width, doorParameters.dimension.height]);

    return (
        <>
            <DropdownMenu
                title={'dimensions'}
                contentHeight={'125px'}
                isMenuOpen={isMenuOpen.dimensions}
                setMenuOpen={() => {
                    let dropdownMenuStructure = new DropdownMenuStructure();
                    dropdownMenuStructure.dimensions = !isMenuOpen.dimensions;
                    setMenuOpen(dropdownMenuStructure);
                }}
                actionButton={<>
                    <FontAwesomeIcon
                        className={'text-gray-300 hover:text-black'}
                        icon={faInfoCircle}
                        onClick={() => {
                            setDimensionHelpModalOpen(!isDimensionHelpModalOpen);
                        }}
                    />
                </>}
            >
                <div className={'bg-slidingdoorco-menu-content-500 flex flex-row'}>
                    <div
                        className={'p-2 justify-center my-auto'}
                        style={{minWidth: '96px', maxWidth: '96px'}}
                    >
                        <img
                            className={'w-full'}
                            src={'/images/icons/dimensions.png'}
                            alt={'Dimension Icon'}
                        />
                    </div>

                    <div className={'w-full my-auto pr-2 flex flex-row gap-1 justify-center'}>
                        <div className={'flex flex-col gap-1 text-white'}>
                            <div>Width</div>
                            <div>Height</div>
                        </div>

                        <div className={'flex flex-col gap-1'}>
                            <div className={'text-white flex'}>
                                <div className={'grow'}>
                                    <input
                                        className={'text-black px-1 w-full dimensions-input'}
                                        type={'number'}
                                        min={doorParameters.dimension.minWidth}
                                        max={doorParameters.dimension.maxWidth}
                                        defaultValue={doorParameters.dimension.defaultWidth}
                                        required={true}
                                        ref={refInputDimensionWidth}
                                        onBlur={() => {
                                            if (!refInputDimensionWidth.current.checkValidity()) {
                                                refInputDimensionWidth.current.value =
                                                    refInputDimensionWidth.current.value < doorParameters.dimension.defaultWidth ?
                                                        doorParameters.dimension.minWidth :
                                                        doorParameters.dimension.maxWidth

                                                eventInputDimensionWidthOnChange();
                                            }
                                        }}
                                        onChange={() => {
                                            eventInputDimensionWidthOnChange();
                                        }}
                                    />
                                </div>
                                <div className={'pl-1 pr-2 text-sm flex flex-col justify-center'}>in</div>
                                <div className={'flex gap-1'}>
                                    <div className={'w-4 text-center'}>
                                        <button
                                            onClick={() => {
                                                addDimensionWidth(-1);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSquareMinus} className={'hover:text-gray-300'}/>
                                        </button>
                                    </div>
                                    <div className={'w-4 text-center'}>
                                        <button
                                            onClick={() => {
                                                addDimensionWidth(1);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSquarePlus} className={'hover:text-gray-300'}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={'text-white flex'}>
                                <div className={'grow'}>
                                    <input
                                        className={'text-black px-1 w-full dimensions-input'}
                                        type={'number'}
                                        min={doorParameters.dimension.minHeight}
                                        max={doorParameters.dimension.maxHeight}
                                        defaultValue={doorParameters.dimension.defaultHeight}
                                        required={true}
                                        ref={refInputDimensionHeight}
                                        onBlur={() => {
                                            if (!refInputDimensionHeight.current.checkValidity()) {
                                                refInputDimensionHeight.current.value =
                                                    refInputDimensionHeight.current.value < doorParameters.dimension.defaultHeight ?
                                                        doorParameters.dimension.minHeight :
                                                        doorParameters.dimension.maxHeight

                                                eventInputDimensionHeightOnChange();
                                            }
                                        }}
                                        onChange={() => {
                                            eventInputDimensionHeightOnChange();
                                        }}
                                    />
                                </div>
                                <div className={'pl-1 pr-2 text-sm flex flex-col justify-center'}>in</div>
                                <div className={'flex gap-1'}>
                                    <div className={'w-4 text-center'}>
                                        <button
                                            onClick={() => {
                                                addDimensionHeight(-1);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSquareMinus} className={'hover:text-gray-300'}/>
                                        </button>
                                    </div>
                                    <div className={'w-4 text-center'}>
                                        <button
                                            onClick={() => {
                                                addDimensionHeight(1);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSquarePlus} className={'hover:text-gray-300'}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'text-xs flex py-1'}>
                    <div className={'px-1'}>*</div>
                    <div className={'grow'}>
                        For accurate quote, please input the dimensions as precisely as possible
                    </div>
                </div>
            </DropdownMenu>

            <ReactModal
                isOpen={isDimensionHelpModalOpen}
                className={'modal-box mx-auto bottom-10 fixed sm:bottom-auto sm:static rounded-none'}
                appElement={document.getElementById('root')}
            >
                <div className={'flex flex-col gap-5'}>
                    <div className={'font-bold'}><u>HOW TO MEASURE:</u></div>
                    <div className={''}>Measure the desired opening in the space where the product would be
                        installed.
                    </div>
                    <div className={''}>
                        <table>
                            <tbody>
                            <tr>
                                <td className={'font-bold'}>Width:</td>
                                <td>Horizontally between the two sides.</td>
                            </tr>
                            <tr>
                                <td className={'font-bold align-top pr-2'}>Height:</td>
                                <td>Vertically from the top of the opening to the bottom of the opening (typically the
                                    floor).
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={''}>Standard doors are 80” or 96” tall. Your opening may be smaller or
                        larger than that. A door probably can be sized to fit if the opening is not exactly standard. In
                        situations where you need something special, we can create a custom door specifically to fit in
                        your unique space.
                    </div>
                </div>
                <div className="modal-action">
                    <button className="btn rounded-none" onClick={() => {
                        setDimensionHelpModalOpen(false)
                    }}>Close
                    </button>
                </div>
            </ReactModal>
        </>
    );
}