import RoomTypeModel from "./RoomTypeModel";
import DimensionModel from "./DimensionModel";
import PanelModel from "./PanelModel";
import FrameFinishModel from "./FrameFinishModel";
import DividerDesignModel from "./DividerDesignModel";
import GlassOpacityModel from "./GlassOpacityModel";
import WallModel from "./WallModel";
import PanelConfigModel from "./PanelConfigModel";
import PriceMultiplierDataModel from "./PriceMultiplierDataModel";
import DoorHandlingModel from "./DoorHandlingModel";
import SwingDirectionModel from "./SwingDirectionModel";


export default class DoorModel {
    constructor(
        id: string,
        name: string,
        image: string,
        dimension: DimensionModel = null,
        panelConfigs: PanelConfigModel[] = null,
        frameFinishes: FrameFinishModel[] = null,
        dividerDesigns: DividerDesignModel[] = null,
        glassOpacities: GlassOpacityModel[] = null,
        doorHandlings: DoorHandlingModel[] = null,
        swingDirections: SwingDirectionModel[] = null,
        roomTypeOptions: RoomTypeModel[] = null,
        priceMultiplierData: PriceMultiplierDataModel[] = null
    ) {
        const getInitialActiveId = (array) => {
            return array == null ?
                null :
                array.length === 0 ?
                    null : array[0].id
                ;
        }

        this.id = id;
        this.name = name;
        this.image = image;

        this.wall = new WallModel();

        this.dimension = dimension;

        this.panelConfigs = panelConfigs;
        this._activePanelConfigId = getInitialActiveId(this.panelConfigs);

        this.frameFinishes = frameFinishes;
        this._activeFrameFinishId = getInitialActiveId(this.frameFinishes);

        this.dividerDesigns = dividerDesigns;
        this._activeDividerDesignId = getInitialActiveId(this.dividerDesigns);

        this.glassOpacities = glassOpacities;
        this._activeGlassOpacityId = getInitialActiveId(this.glassOpacities);

        this.doorHandlings = doorHandlings;
        this._activeDoorHandlingId = getInitialActiveId(this.doorHandlings);

        this.swingDirections = swingDirections;
        this._activeSwingDirectionId = getInitialActiveId(this.swingDirections);

        this.roomTypeOptions = roomTypeOptions;
        this._activeRoomTypeOptionId = getInitialActiveId(this.roomTypeOptions);

        this.priceMultiplierData = priceMultiplierData;
    }

    createClone = (): this => {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
    }

    //==================== Actions for Frame Finishes ====================
    setActivePanelConfigOption = (panelConfigOptionId: string): void => {
        this._activePanelConfigId = panelConfigOptionId;
    }

    getActivePanelConfigOption = (): PanelConfigModel => {
        for (let index = 0; index < this.panelConfigs.length; index++) {
            if (this._activePanelConfigId === this.panelConfigs[index].id) {
                return this.panelConfigs[index]
            }
        }

        return this.panelConfigs.length !== 0 ? this.panelConfigs[0] : null;
    }

    //==================== Actions for Frame Finishes ====================
    setActiveFrameFinishOption = (frameFinishOptionId: string): void => {
        this._activeFrameFinishId = frameFinishOptionId;
    }

    getActiveFrameFinishOption = (): FrameFinishModel => {
        for (let index = 0; index < this.frameFinishes.length; index++) {
            if (this._activeFrameFinishId === this.frameFinishes[index].id) {
                return this.frameFinishes[index]
            }
        }

        return this.frameFinishes.length !== 0 ? this.frameFinishes[0] : null;
    }

    //==================== Actions for Divider Design ====================
    setActiveDividerDesignOption = (deviderDesignOptionId: string): void => {
        this._activeDividerDesignId = deviderDesignOptionId;
    }

    getActiveDividerDesignOption = (): DividerDesignModel => {
        for (let index = 0; index < this.dividerDesigns.length; index++) {
            if (this._activeDividerDesignId === this.dividerDesigns[index].id) {
                return this.dividerDesigns[index];
            }
        }

        return this.dividerDesigns.length !== 0 ? this.dividerDesigns[0] : null;
    }

    //==================== Actions for Glass Opacities ====================
    setActiveGlassOpacityOption = (glassOpacityOptionId: string): void => {
        this._activeGlassOpacityId = glassOpacityOptionId;
    }

    getActiveGlassOpacityOption = (): GlassOpacityModel => {
        for (let index = 0; index < this.glassOpacities.length; index++) {
            if (this._activeGlassOpacityId === this.glassOpacities[index].id) {
                return this.glassOpacities[index];
            }
        }

        return this.glassOpacities.length !== 0 ? this.glassOpacities[0] : null;
    }

    //==================== Actions for Door Handling ====================
    setActiveDoorHandlingOption = (doorHandlingId: string): void => {
        this._activeDoorHandlingId = doorHandlingId;
    }

    getActiveDoorHandlingOption = (): DoorHandlingModel => {
        for (let index = 0; index < this.doorHandlings.length; index++) {
            if (this._activeDoorHandlingId === this.doorHandlings[index].id) {
                return this.doorHandlings[index];
            }
        }

        return this.doorHandlings.length !== 0 ? this.doorHandlings[0] : null;
    }

    //==================== Actions for SwingDirection ====================
    setActiveSwingDirectionOption = (swingDirectionId: string): void => {
        this._activeSwingDirectionId = swingDirectionId;
    }

    getActiveSwingDirectionOption = (): SwingDirectionModel => {
        for (let index = 0; index < this.swingDirections.length; index++) {
            if (this._activeSwingDirectionId === this.swingDirections[index].id) {
                return this.swingDirections[index];
            }
        }

        return this.swingDirections.length !== 0 ? this.swingDirections[0] : null;
    }

    //==================== Actions for Room Type ====================
    setActiveRoomTypeOption = (roomTypeOptionId: string): void => {
        this._activeRoomTypeOptionId = roomTypeOptionId;
    }

    getActiveRoomTypeOption = (): RoomTypeModel => {
        for (let index = 0; index < this.roomTypeOptions.length; index++) {
            if (this._activeRoomTypeOptionId === this.roomTypeOptions[index].id) {
                return this.roomTypeOptions[index];
            }
        }

        return this.roomTypeOptions.length !== 0 ? this.roomTypeOptions[0] : null;
    }

    setNextActiveRoomTypeOption = (): void => {
        if (this.roomTypeOptions.length === 0) {
            return
        }

        let currentActiveIndex = 0;

        for (let index = 0; index < this.roomTypeOptions.length; index++) {
            if (this._activeRoomTypeOptionId === this.roomTypeOptions[index].id) {
                currentActiveIndex = index;
                break;
            }
        }

        currentActiveIndex += 1;

        if (currentActiveIndex === this.roomTypeOptions.length) {
            currentActiveIndex = 0
        }

        this.setActiveRoomTypeOption(this.roomTypeOptions[currentActiveIndex].id);
    }

    setPreviousActiveRoomTypeOption = (): void => {
        if (this.roomTypeOptions.length === 0) {
            return
        }

        let currentActiveIndex = 0;

        for (let index = 0; index < this.roomTypeOptions.length; index++) {
            if (this._activeRoomTypeOptionId === this.roomTypeOptions[index].id) {
                currentActiveIndex = index;
                break;
            }
        }

        currentActiveIndex -= 1;

        if (currentActiveIndex < 0) {
            currentActiveIndex = this.roomTypeOptions.length - 1;
        }

        this.setActiveRoomTypeOption(this.roomTypeOptions[currentActiveIndex].id);
    }
}