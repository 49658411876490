import React, {useContext, useEffect, useState, createContext} from "react";
import {DoorContext} from "controllers/DoorController";
import {RefContext} from "../index";

const DoorPreviewContext = createContext(null);

export default function DoorPreview(props) {
    const {
        width, height, containerHeight, panelAnimationIndex, setPanelAnimationIndex
    } = props;

    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {
        doorPreviewRef,
        setDoorPreviewRefWidth,
        setDoorPreviewRefHeight
    } = useContext(RefContext);

    const [frameWidth, setFrameWidth] = useState(7);
    const [frameClipPath, setFrameClipPath] = useState('');

    useEffect(() => {
        setDoorPreviewRefWidth(doorPreviewRef.current.clientWidth);
    }, [width]);
    useEffect(() => {
        setDoorPreviewRefHeight(doorPreviewRef.current.clientHeight);
    }, [height]);

    useEffect(() => {
        let newFrameWidth = containerHeight * .006;

        setFrameWidth(newFrameWidth);
        setFrameClipPath(
            'polygon(' +
            '0% 100%, ' +
            '0% 0%, ' +
            '100% 0%, ' +
            '100% 100%, ' +
            'calc(100% - ' + newFrameWidth + 'px) 100%, ' +
            'calc(100% - ' + newFrameWidth + 'px) calc(100% - ' + newFrameWidth + 'px), ' +
            'calc(100% - ' + newFrameWidth + 'px) ' + newFrameWidth + 'px, ' +
            '' + newFrameWidth + 'px ' + newFrameWidth + 'px, ' +
            '' + newFrameWidth + 'px calc(100% - ' + newFrameWidth + 'px), ' +
            'calc(100% - ' + newFrameWidth + 'px) calc(100% - ' + newFrameWidth + 'px), ' +
            'calc(100% - ' + newFrameWidth + 'px) 100% ' +
            ')'
        )
    }, [containerHeight]);

    useEffect(() => {
        setPanelAnimationIndex(0);
    }, [doorParameters.getActivePanelConfigOption()]);

    useEffect(() => {
        if (doorParameters.id !== 'swing_doors') return;

        const clone = doorParameters.createClone();

        let currentActiveAnimation = '';

        if (doorParameters.getActivePanelConfigOption().panels.length === 1) {
            currentActiveAnimation += doorParameters.getActiveDoorHandlingOption().id + '_';
        }

        currentActiveAnimation += doorParameters.getActiveSwingDirectionOption().id;

        for (let panel of clone.getActivePanelConfigOption().panels) {
            panel.setActiveAnimationOption(currentActiveAnimation);
        }

        setDoorParameters(clone);
        setPanelAnimationIndex(0);
    }, [
        doorParameters.getActiveDoorHandlingOption(),
        doorParameters.getActiveSwingDirectionOption(),
        doorParameters.getActivePanelConfigOption()
    ]);

    return (
        <DoorPreviewContext.Provider value={{
            width,
            height,
            frameWidth,
            frameClipPath,
            containerHeight,
            panelAnimationIndex
        }}>
            <div className={'absolute w-full bottom-0'}>
                {
                    doorParameters.id === 'wall_slide_doors' ?
                        <WallSlideExtraFrame/>
                        :
                        <></>
                }

                <div
                    className={'mx-auto'}
                    style={{
                        width: width,
                        height: height,
                    }}
                >
                    <div
                        className={'w-full h-full'}
                    >

                        <div
                            className={'w-full h-full brightness-95'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                            }}
                        />
                        <DoorPanel isPreview={false}/>
                    </div>
                </div>
            </div>

            <div className={'absolute w-full top-0 opacity-0'}>
                <div
                    className={'mx-auto'}
                    style={{
                        width: width,
                        height: height,
                    }}
                >
                    <div
                        className={'w-full h-full'}
                        ref={doorPreviewRef}
                    >

                        <div
                            className={'w-full h-full'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                            }}
                        />
                        <DoorPanel isPreview={true}/>
                    </div>
                </div>
            </div>

        </DoorPreviewContext.Provider>
    );
};

const WallSlideExtraFrame = () => {
    const {doorParameters} = useContext(DoorContext);
    const {width, height, frameWidth} = useContext(DoorPreviewContext);

    return (
        <>
            {
                doorParameters.getActivePanelConfigOption().panels.length === 1 ?
                    <>
                        <div
                            className={'absolute brightness-95'}
                            style={{
                                width: width,
                                height: frameWidth + 'px',
                                marginLeft: 'calc(calc(calc(50% - calc(' + width + ' / 2)) - ' + width + ') + ' + frameWidth + 'px)',
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                            }}
                        />
                        <div
                            className={'absolute brightness-95'}
                            style={{
                                width: width,
                                height: frameWidth + 'px',
                                marginLeft: 'calc(calc(calc(50% - calc(' + width + ' / 2)) - ' + width + ') + ' + frameWidth + 'px)',
                                marginTop: 'calc(' + height + ' - ' + frameWidth + 'px)',
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                            }}
                        />
                    </>
                    :
                    <>
                        <div
                            className={'absolute brightness-95'}
                            style={{
                                width: 'calc(' + width + ' / 2)',
                                height: frameWidth + 'px',
                                marginLeft: 'calc(calc(calc(50% - calc(' + width + ' / 2)) - calc(' + width + ' / 2)) + ' + frameWidth * .5 + 'px)',
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                            }}
                        />

                        <div
                            className={'absolute brightness-95'}
                            style={{
                                width: 'calc(' + width + ' / 2)',
                                height: frameWidth + 'px',
                                marginLeft: 'calc(calc(50% + calc(' + width + ' / 2)) - ' + frameWidth * .5 + 'px)',
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                            }}
                        />

                        <div
                            className={'absolute brightness-95'}
                            style={{
                                width: 'calc(' + width + ' / 2)',
                                height: frameWidth + 'px',
                                marginLeft: 'calc(calc(calc(50% - calc(' + width + ' / 2)) - calc(' + width + ' / 2)) + ' + frameWidth * .5 + 'px)',
                                marginTop: 'calc(' + height + ' - ' + frameWidth + 'px)',
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                            }}
                        />

                        <div
                            className={'absolute brightness-95'}
                            style={{
                                width: 'calc(' + width + ' / 2)',
                                height: frameWidth + 'px',
                                marginLeft: 'calc(calc(50% + calc(' + width + ' / 2)) - ' + frameWidth * .5 + 'px)',
                                marginTop: 'calc(' + height + ' - ' + frameWidth + 'px)',
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                            }}
                        />
                    </>
            }
        </>
    );
};

const DoorPanel = (props) => {
    const {isPreview} = props

    const {width, height, frameWidth, panelAnimationIndex} = useContext(DoorPreviewContext);

    const {doorParameters} = useContext(DoorContext);

    return (
        <>
            <div
                style={{
                    height: 'calc(' + height + ' - ' + frameWidth + 'px)',
                    width: 'calc(100% - ' + frameWidth + 'px)',
                    marginTop: 'calc(-1 * ' + height + ' + ' + frameWidth / 2 + 'px)',
                    marginLeft: frameWidth / 2 + 'px',
                }}
            >
                {
                    doorParameters.getActivePanelConfigOption().panels.map((panel, index) => {
                        const activePanelAnimationIndex = isPreview ? 0 : panelAnimationIndex % panel.getActiveAnimationOption().animation.length;

                        return (
                            <React.Fragment key={index}>
                                <div
                                    className={'h-full relative float-left duration-500'}
                                    style={{
                                        width: 'calc(100% / ' + doorParameters.getActivePanelConfigOption().panels.length + ' + 2px)',
                                        marginRight: '-1px',
                                        marginLeft: '-1px',
                                        perspective: '1000px',
                                        ...panel.getActiveAnimationOption().animation[activePanelAnimationIndex],
                                        transform: ''
                                    }}
                                >
                                    <div
                                        className={
                                            'h-full duration-500'
                                        }
                                        style={{
                                            ...panel.getActiveAnimationOption().animation[activePanelAnimationIndex]
                                        }}
                                    >
                                        <div
                                            className={'flex flex-col h-full justify-center'}
                                            style={{
                                                backgroundImage: 'url(' + doorParameters.getActiveGlassOpacityOption().image + ')',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                ...doorParameters.getActiveGlassOpacityOption().style,
                                                ...panel.style,
                                            }}
                                        >
                                            <DoorDividerDesign/>
                                            <DoorHandle index={index}/>

                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })
                }
            </div>
        </>
    );
};

const DoorHandle = (props) => {
    const {frameWidth, containerHeight} = useContext(DoorPreviewContext);
    const {doorParameters} = useContext(DoorContext);
    const {index} = props;

    if (doorParameters.getActiveDoorHandlingOption() === null) {
        return (<></>);
    }

    let isHandleOnRight = true;

    if (doorParameters.getActivePanelConfigOption().panels.length === 1) {
        isHandleOnRight = doorParameters.getActiveDoorHandlingOption().id === 'right_handle';
    } else {
        isHandleOnRight = index === 0;
    }

    return (
        <>
            <div
                className={
                    ''
                }
            >
                <div
                    className={
                        'h-0 w-full absolute flex '
                        +
                        (
                            isHandleOnRight ?
                                'justify-end'
                                :
                                ''
                        )
                    }
                    style={{
                        bottom: (containerHeight * 40 * .00194) + 'px'
                    }}
                >
                    {
                        doorParameters.getActivePanelConfigOption().panels.length === 1 ?
                            <>
                                <div
                                    className={'flex justify-center'}
                                    style={{
                                        width: (frameWidth * 2.5) + 'px',
                                        height: (frameWidth * 2) + 'px',
                                        marginTop: '-' + (frameWidth * 1) + 'px',
                                        marginRight: ' ' + (frameWidth * .05) + 'px',
                                        marginLeft: ' ' + (frameWidth * .05) + 'px',
                                    }}
                                >
                                    <img
                                        alt={'single panel door handle'}
                                        src={'/images/door_handle/single_panel_handle.png'}
                                        className={'my-auto'}
                                        style={{
                                            transform:
                                                isHandleOnRight ?
                                                    'rotate(180deg)'
                                                    :
                                                    ''
                                        }}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div
                                    className={'flex justify-center overflow-hidden rounded-sm'}
                                    style={{
                                        width: (frameWidth * .5) + 'px',
                                        height: (frameWidth * 5) + 'px',
                                        marginTop: '-' + (frameWidth * 5 / 2) + 'px',
                                        marginRight: ' ' + (frameWidth * .25 / 1.5) + 'px',
                                        marginLeft: ' ' + (frameWidth * .25 / 1.5) + 'px',
                                    }}
                                >
                                    <img
                                        alt={'single panel door handle'}
                                        src={'/images/door_handle/double_panel_handle.jpg'}
                                        className={'my-auto'}
                                    />
                                </div>
                            </>
                    }
                </div>

            </div>
        </>
    );
}

const DoorDividerDesign = (props) => {
    const {doorParameters} = useContext(DoorContext);
    const {frameWidth, frameClipPath} = useContext(DoorPreviewContext);

    return (
        <>
            {
                {
                    'Solo': <>
                        <div
                            className={'grow bg-blue-200'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25) inset',
                                clipPath: frameClipPath,
                            }}
                        />
                    </>,
                    'Trio': <>
                        <div
                            className={'grow'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                            }}
                        />
                        <div
                            className={'grow'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                                marginTop: '-' + frameWidth + 'px',
                                marginBottom: '-' + frameWidth + 'px'
                            }}
                        />
                        <div
                            className={'grow'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                            }}
                        />
                    </>,
                    'Quattro': <>
                        <div
                            className={'grow'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                            }}
                        />
                        <div
                            className={'grow'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                                marginTop: '-' + frameWidth + 'px',
                                marginBottom: '-' + frameWidth + 'px'
                            }}
                        />
                        <div
                            className={'grow'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                                marginBottom: '-' + frameWidth + 'px'
                            }}
                        />
                        <div
                            className={'grow'}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                            }}
                        />
                    </>,
                    'DuoT': <>
                        <div
                            className={'grow flex'}
                        >
                            <div
                                style={{
                                    backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                    clipPath: frameClipPath,
                                    width: 'calc(50% + ' + (frameWidth / 2) + 'px)',
                                    marginRight: '-' + (frameWidth / 2) + 'px'
                                }}
                            />
                            <div
                                style={{
                                    backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                    clipPath: frameClipPath,
                                    width: 'calc(50% + ' + (frameWidth / 2) + 'px)',
                                    marginLeft: '-' + (frameWidth / 2) + 'px'
                                }}
                            />
                        </div>
                        <div
                            className={''}
                            style={{
                                backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                clipPath: frameClipPath,
                                flexGrow: 2,
                                marginTop: '-' + frameWidth + 'px',
                                marginBottom: '-' + frameWidth + 'px'
                            }}
                        />
                        <div
                            className={'grow flex'}
                        >
                            <div
                                style={{
                                    backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                    clipPath: frameClipPath,
                                    width: 'calc(50% + ' + (frameWidth / 2) + 'px)',
                                    marginRight: '-' + (frameWidth / 2) + 'px'
                                }}
                            />
                            <div
                                style={{
                                    backgroundImage: 'url(' + doorParameters.getActiveFrameFinishOption().image + ')',
                                    clipPath: frameClipPath,
                                    width: 'calc(50% + ' + (frameWidth / 2) + 'px)',
                                    marginLeft: '-' + (frameWidth / 2) + 'px'
                                }}
                            />
                        </div>
                    </>
                }[doorParameters.getActiveDividerDesignOption().id]
            }
        </>
    );
};